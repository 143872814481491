import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { QuickSendContext } from "contexts/QuickSend";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { Dispatch, SetStateAction, useContext, useState } from "react";

import { useMergeTags } from "components/Editor/MergeTag/useMergeTags";
import { extensions } from "components/Editor/utils";
import { MERGE_TAGS } from "constants/templates";
import { Template } from "features/Influencer/ContactList/schema";
import TemplateBody from "features/Influencer/Templates/SharedComponents/TemplateBody";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy, updateList } from "utils/updateLocalState";
import useTemplate from "utils/useTemplate";

interface Props {
  selectedTemplate: Template | null;
  setSelectedTemplate: Dispatch<SetStateAction<Template | null>>;
}

const SingleTemplate = ({ selectedTemplate, setSelectedTemplate }: Props) => {
  const { filledInOrgProfile, replaceMergeTagWithVariable } = useTemplate();
  const { templates, setTemplates, setCurrentDraftBody } = useContext(
    OutreachTemplatesContext,
  );
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setAlert, setErrorAlert } = useContext(AlertContext);
  const { getToken } = useAuth();

  const { draftView, view } = useContext(QuickSendDrawerContext);
  // Get contacts for Template Insertions
  const { currentContact } = useContext(OutreachContactsContext);
  const { contactName, bentoBrand } = useContext(QuickSendContext);

  const [toggleMergeTagTooltip, toggleVariableTooltip] = useMergeTags();
  const [loading, setLoading] = useState(false);

  const editor = useEditor(
    {
      extensions: extensions({
        toggleVariableTooltip,
        toggleMergeTagTooltip,
        suggestionOptions: MERGE_TAGS,
      }),
      content: selectedTemplate?.body,
      onUpdate({ editor }) {
        setSelectedTemplate((prev) => {
          const copy = makeDeepCopy(prev);
          copy.body = editor.getHTML();
          return copy;
        });
      },
    },
    [!!selectedTemplate?.body],
  ) as TipTapEditor;

  const handleSaveTemplate = async () => {
    setLoading(true);

    trackEvent("Save Template Button Clicked", { from: "Sidebar" });
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates/${selectedTemplate?.id}`,
        "PUT",
        {},
        {
          ...selectedTemplate,
        },
      );
      setAlert("Template Saved", "success");
      updateList(templates, setTemplates, res.template, "id");
      filledInOrgProfile();
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInsertContent = () => {
    trackEvent("Insert Content From Template", {
      "Template ID": selectedTemplate?.id,
    });

    const body = editor.getHTML();
    let replaced = "";

    if (currentContact && currentContact.brand?.name) {
      replaced = replaceMergeTagWithVariable(
        body,
        currentContact?.contactName || `${currentContact?.brand?.name} Team`,
        currentContact?.brand?.name,
      );
    } else if (bentoBrand?.brandName && view === QuickSendTabView.EXPAND) {
      replaced = replaceMergeTagWithVariable(
        body,
        contactName || `${bentoBrand?.brandName} Team`,
        bentoBrand?.brandName,
      );
    } else {
      replaced = body;
    }
    setCurrentDraftBody(replaced);
  };

  return (
    <Box sx={{ pb: 15 }}>
      <Box sx={{ p: 1 }}>
        {selectedTemplate && (
          <TemplateBody
            editTemplate={selectedTemplate}
            // @ts-ignore
            setEditTemplate={setSelectedTemplate}
            minimalist={true}
          />
        )}
      </Box>

      <Grid
        container
        gap={1}
        alignItems="center"
        justifyContent="flex-end"
        sx={{ pr: 2, mt: 2 }}
      >
        {(currentContact ||
          draftView === QuickSendTabView.EXPAND ||
          view === QuickSendTabView.EXPAND) && (
          <Button size="small" variant="outlined" onClick={handleInsertContent}>
            Insert
          </Button>
        )}
        <LoadingButton
          loading={loading}
          onClick={handleSaveTemplate}
          disableElevation
          size="small"
          variant="contained"
        >
          Save Changes{" "}
        </LoadingButton>
      </Grid>
    </Box>
  );
};

export default SingleTemplate;
