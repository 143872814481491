import { Link, Typography } from "@mui/material";
import { AllPlans, SubscriptionTab } from "contexts/Subscription";
import {
  Organization,
  Subscription,
  SubscriptionType,
} from "schemas/dashboard";
import { Plans, getEquivalentPlansFromOverride } from "schemas/payments";

import {
  TERMS_AND_CONDITIONS_LINK,
  VA_LANDING_PAGE_LINK,
} from "constants/influencer";
import { titleCase } from "utils/string";
import { formatEpochTimestamp } from "utils/time";
import { trackEvent } from "utils/tracking";

type PlanData = {
  emailSents: number;
  brandRequestsMade: number;
  customizedMessagesDrafted: number;
  recommendationsRefreshed: number;
  tab: SubscriptionTab;
};

export const disabledCurrentPlan = (
  plan: Plans,
  subscription: Subscription | null,
) => {
  if (plan === Plans.FREE) return true;
  if (subscription?.overridePlanName) {
    return getEquivalentPlansFromOverride(
      subscription.overridePlanName,
    )?.includes(plan);
  }
  return false;
};

export const getCurrentPlanButton = (
  plan: Plans,
  subscription: Subscription | null,
) => {
  const isButtonDisabled = disabledCurrentPlan(plan, subscription);
  if (isButtonDisabled) {
    return "Current Plan";
  } else if (subscription?.subscription?.status === SubscriptionType.CANCELED) {
    return "Resubscribe";
  } else if (subscription?.subscription.cancelAt) {
    return "Renew Subscription";
  } else {
    return "Manage Current Plan";
  }
};

export const getOtherPlanButton = (
  tier: Plans,
  userCurrentPlan?: Plans,
  org?: Organization | null,
  fromOnboarding = false,
) => {
  const orders = {
    [Plans.FREE]: 0,
    [Plans.STARTER]: 1,
    [Plans.STARTER_YEARLY]: 1,
    [Plans.PRO]: 2,
    [Plans.PRO_YEARLY]: 2,
    [Plans.VA]: 3,
    [Plans.VA_YEARLY]: 3,
  };
  if (fromOnboarding) return "Pay Now";
  if (userCurrentPlan && orders[userCurrentPlan] >= orders[tier]) {
    return "Choose This Plan";
  } else {
    return "Upgrade";
  }
};

export const getStatusChipLabel = (subscription: Subscription | null) => {
  const stripePlan = subscription?.subscription;
  if (stripePlan?.status === SubscriptionType.TRIALING) {
    return "Active";
  } else {
    return titleCase(stripePlan?.status || "");
  }
};

const trackTermsAndCondition = () => {
  trackEvent("Terms and Conditions for VA Plan Clicked");
};

const trackLearnMore = () => {
  trackEvent("VA Landing Page Clicked");
};

export const getSubscriptionInformation = (
  plan: Plans,
  plans: AllPlans | null,
  subscription: Subscription | null,
  currentOrg: Organization | null,
  data: PlanData,
) => {
  const {
    emailSents,
    brandRequestsMade,
    customizedMessagesDrafted,
    recommendationsRefreshed,
  } = data;
  const stripePlan = subscription?.subscription;
  const isCurrentPlan = stripePlan?.planName === plan;
  const price = plans ? plans[plan].price : 0;
  const resubscribe =
    getCurrentPlanButton(plan, subscription) === "Resubscribe";
  const renew =
    getCurrentPlanButton(plan, subscription) === "Renew Subscription";

  if (!plans)
    return {
      stripePlan,
      isCurrentPlan,
      price,
      description: <></>,
      resubscribe,
      renew,
    };

  const totalEmails =
    plans[plan].monthly_limit + Number(subscription?.referralsBoost || 0);
  const totalBrandRequests = plans[plan].brand_requests_monthly_limit;
  const totalAIMessages = plans[plan].chat_monthly_limit;
  const totalRefreshes = plans[plan].refresh_recommendations_limit;

  const specificPlanBenefits = (
    <>
      {(plan === Plans.PRO || plan === Plans.PRO_YEARLY) && (
        <>
          <br />
          Access to "Reply Score" feature
        </>
      )}
    </>
  );

  const planLimitReset = (
    <>
      {subscription && isCurrentPlan && (
        <>
          <br />
          <br />
          Your plan's limits will reset on{" "}
          <strong>
            {formatEpochTimestamp({
              epochTimestamp: subscription.creditsCycle[1],
              addYear: true,
            })}
          </strong>
          .
        </>
      )}
    </>
  );

  const getDescription = () => {
    if (plan === Plans.VA) {
      return (
        <>
          A service that allows you to put pitching on autopilot. Get your time
          back and have someone pitch up to 100 brands per week for you.
        </>
      );
    } else if (plan === Plans.VA_YEARLY) {
      return (
        <>
          Bento's Virtual Assistant (money-back guaranteed) service allows
          creators to sit back and wait for brands to respond to them without
          lifting a finger. Bento's Virtual Assistant's will do all your
          pitching for you.
          <br />
          <br />
          And the best part?
          <br />
          <br />
          If you don't earn more than you pay, you get your money back.{" "}
          <Link
            sx={{ color: "info.main", textDecoration: "none" }}
            href={VA_LANDING_PAGE_LINK}
            target="_blank"
            onClick={trackLearnMore}
          >
            <strong>Learn More</strong>
          </Link>
          <br />
          <br />
          <a
            href={TERMS_AND_CONDITIONS_LINK}
            target="_blank"
            rel="noopener noreferrer"
            onClick={trackTermsAndCondition}
          >
            Terms & Conditions Apply
          </a>
        </>
      );
    } else if (isCurrentPlan) {
      return (
        <>
          {subscription?.overridePlanName && isCurrentPlan && (
            <>
              <strong>
                To try out the platform, we have gifted you the{" "}
                {titleCase(subscription?.overridePlanName)} plan.
              </strong>
              <br />
              <br />
            </>
          )}
          <Typography component="span" sx={{ color: "info.main" }}>
            <strong>{emailSents}</strong>
          </Typography>
          /{totalEmails} Emails Sent used this month{" "}
          {subscription && subscription.referralsBoost > 0 && (
            <> (including +{subscription.referralsBoost} from referrals)</>
          )}
          <br />
          <Typography component="span" sx={{ color: "info.main" }}>
            <strong>{customizedMessagesDrafted}</strong>
          </Typography>
          /{totalAIMessages} AI-customized Emails used this month
          <br />
          <Typography component="span" sx={{ color: "info.main" }}>
            <strong>{brandRequestsMade}</strong>
          </Typography>
          /{totalBrandRequests} Brand Requests used this month
          <br />
          <Typography component="span" sx={{ color: "info.main" }}>
            <strong>{recommendationsRefreshed}</strong>
          </Typography>
          /{totalRefreshes} Recommendations Refreshes used this month
          {planLimitReset}
        </>
      );
    } else {
      return (
        <>
          Send {totalEmails} Emails per month
          <br />
          Get {totalAIMessages} AI-customized emails per month
          <br />
          Get {totalBrandRequests} brand requests per month
          <br />
          Get {totalRefreshes} Recommendations Refreshes per month
        </>
      );
    }
  };

  const description = (
    <>
      {getDescription()} {specificPlanBenefits}
    </>
  );

  return {
    stripePlan,
    isCurrentPlan,
    price,
    description,
    resubscribe,
    renew,
  };
};
