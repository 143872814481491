import { BentoBrand, EmailSequence, PreviewMessage } from "schemas/dashboard";

import { Template } from "features/Influencer/ContactList/schema";

export type CategoryMap = {
  [key: string]: {
    emailSequences: EmailSequence[];
    fallbackTemplates: Template[];
  };
};

export type EmailSequenceBe = {
  bentoBrandId: number;
  sequenceUuid?: string;
  fallbackTemplateIds: number[];
};

export type Preview = {
  bentoBrand: BentoBrand;
  templates: Template[];
  outreachMessages: PreviewMessage[];
};

export enum DialogScreen {
  send_option = "send_option",
  edit_brands_list = "edit_brands_list",
  preview_email = "preview_email",
}
