import { Theme, lighten } from "@mui/material";
import { grey } from "@mui/material/colors";

import { getZIndex } from "./helpers";

const styles = {
  th: {
    borderTop: `1px solid ${grey[300]}`,
    px: 1,
    whiteSpace: "nowrap",
    opacity: 1,
    position: "sticky",
    cursor: "pointer",
    transition: "width transform 0.2s ease-in-out",
  },
  tableCell: (isSticky: boolean) => ({
    position: "sticky",
    zIndex: getZIndex(isSticky),
    left: isSticky ? 0 : undefined,
    borderRight: `1px solid ${grey[300]}`,
  }),
  normalBg: {
    backgroundColor: "yellow.light",
    "&:hover": {
      backgroundColor: "yellow.main",
    },
  },
  filterColor: {
    backgroundColor: "primary.light",
    color: "black",
    "&:hover": (theme: Theme) => ({
      backgroundColor: lighten(theme.palette.primary.light, 0.3),
    }),
  },
  sortColor: {
    backgroundColor: "#d6eaf8",
    color: "black",
    "&:hover": {
      backgroundColor: "#ebf5fb",
    },
  },
  readOnly: {
    color: grey[600],
  },

  icon: { fontSize: 12, color: "black", width: 20, height: 20 },
};

export default styles;
