import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendContactsContext } from "contexts/QuickSendContacts";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { SubscriptionContext } from "contexts/Subscription";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { BentoBrand, BentoContact, NewBentoContact } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import AddNewContactDialog from "features/Influencer/QuickSendIndividualDrawer/AddNewContact";
import { fetcherAuth } from "utils/api";
import { hideEmail } from "utils/string";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useRequests } from "utils/useRequests";

import ContactScore from "./ContactScore";
import ViewScoreDialog from "./ViewScoreDialog";
import styles from "./styles";

interface SelectContactProps {
  handleAddNewContact?: (
    newContact: NewBentoContact,
    handleClose: () => void,
  ) => void;
  brand: BentoBrand;
}

export default function SelectContact({
  handleAddNewContact,
  brand,
}: SelectContactProps) {
  const { updateContactNotifications } = useRequests();
  const { quickSendBrandId, previewLoading } = useContext(
    QuickSendDrawerContext,
  );
  const { setAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const {
    templateForQuickSend,
    setTemplateForQuickSend,
    handleRefetchPreviews,
    bentoContact,
  } = useContext(QuickSendContext);
  const { bentoContacts, fromMultipleCountries } = useContext(
    QuickSendContactsContext,
  );
  const { canMakeBrandRequest, setUpgradeDialogSource } =
    useContext(SubscriptionContext);
  const { getLastContacted } = useContext(QuickSendContactsContext);
  const { openAddNewContact, setOpenAddNewContact } = useContext(
    QuickSendDrawerContext,
  );
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openViewScore, setOpenViewScore] = useState(false);

  const defaultNewContact = {
    bentoBrandId: brand?.id,
    name: "",
    email: "",
    title: "",
  };
  const [contact, setContact] = useState<NewBentoContact>(defaultNewContact);

  const handleCloseAddContactDialog = () => {
    setOpenAddNewContact(false);
  };

  const handleConfirm = (newContact: NewBentoContact) => {
    trackEvent("Add New Contact Button Clicked");
    if (handleAddNewContact)
      handleAddNewContact(newContact, handleCloseAddContactDialog);
  };

  const handleNotify = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/additional-contact-notifications`,
        "POST",
        {},
        {
          bentoBrandId: brand?.id,
        },
      );
      updateContactNotifications(res.contactNotification);
      setAlert(
        `We will notify you when a new contact is found for ${brand?.brandName}`,
        "success",
      );
    } catch (error) {
      setAlert(
        "Failed to submit request. Please reach out to hello@onbento.com for assistance",
        "error",
      );
    }
  };

  const handleChangeContact = (e: CustomEvent) => {
    const { value } = e.target;
    trackEvent("Contact Changed", {
      contact: value,
    });
    if (value === "new_contact") {
      setContact(defaultNewContact);
      setOpenAddNewContact(true);
    } else if (value === "notify_contact") {
      if (!canMakeBrandRequest()) {
        setUpgradeDialogSource("Hits Brand Request Limit");
        return;
      }
      handleNotify();
    } else {
      const copy = makeDeepCopy(templateForQuickSend);
      setTemplateForQuickSend(copy);
      handleRefetchPreviews(copy, value);
    }
  };

  const getFirstName = (name: string) => {
    if (name.includes("Team")) {
      return name;
    } else {
      return name.split(" ")[0];
    }
  };

  const renderContactSelectTitle = (contactEmail: string) => {
    const contact = sortedContacts?.find((x) => x.email === contactEmail);
    let titleAndName = "";
    if (contact?.name && contact?.title) {
      titleAndName = `${getFirstName(contact.name)}, ${contact.title}`;
    } else if (contact?.name) {
      titleAndName = getFirstName(contact.name);
    } else if (contact?.title) {
      titleAndName = contact.title;
    }
    const hiddenEmail = hideEmail(contactEmail);
    const locationText =
      fromMultipleCountries && contact?.location
        ? `, ${contact?.location}`
        : "";
    return (
      <>
        {" "}
        {titleAndName}
        {locationText} ({hiddenEmail})
      </>
    );
  };

  const formatLastContacted = (contact: BentoContact | NewBentoContact) => {
    const lastContacted = getLastContacted(contact.email);
    if (lastContacted === undefined) {
      return (
        <CircularProgress
          sx={styles.loadingContactDate}
          size={10}
          disableShrink
        />
      );
    } else {
      return lastContacted ? (
        <Typography
          sx={styles.lastContacted}
          component={isMobileScreen ? "div" : "span"}
        >
          Contacted on {moment(lastContacted)?.format("YYYY-MMM-DD")}
        </Typography>
      ) : (
        <></>
      );
    }
  };

  function compare(
    a: BentoContact | NewBentoContact,
    b: BentoContact | NewBentoContact,
  ) {
    return (
      moment(getLastContacted(b?.email)).unix() -
      moment(getLastContacted(a?.email)).unix()
    );
  }

  const sortedContacts = useMemo(
    () => bentoContacts?.sort(compare),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bentoContacts, getLastContacted],
  );

  const isLoading = previewLoading === quickSendBrandId;

  const renderContacts = () => {
    if (isLoading) {
      return (
        <Box sx={[styles.subjectWrapper, styles.border]}>
          <Typography component="span" sx={styles.annotation}>
            To:
          </Typography>
          <Skeleton animation="wave" width={100} />
        </Box>
      );
    } else {
      return (
        <Box sx={[styles.subjectWrapper, styles.border]}>
          <Typography component="span" sx={styles.annotation}>
            To:
          </Typography>

          {bentoContacts?.length > 0 ? (
            <>
              <FormControl>
                <Select
                  size="small"
                  value={bentoContact?.email}
                  onChange={handleChangeContact}
                  sx={styles.noSelectBorder}
                  renderValue={(contactEmail) =>
                    renderContactSelectTitle(contactEmail)
                  }
                >
                  {sortedContacts?.map(
                    (
                      contact: BentoContact | NewBentoContact,
                      index: number,
                    ) => (
                      <MenuItem
                        key={index}
                        value={contact?.email}
                        style={{ whiteSpace: "normal" }}
                      >
                        {renderContactSelectTitle(contact?.email)}{" "}
                        {formatLastContacted(contact)}
                      </MenuItem>
                    ),
                  )}
                  <MenuItem value="new_contact" sx={{ color: "green.main" }}>
                    <em>
                      Add your own contact{" "}
                      <Box component="i" className="fa-regular fa-edit" />
                    </em>
                  </MenuItem>
                  <MenuItem value="notify_contact" sx={{ color: "green.main" }}>
                    <em>
                      Request more contacts for this brand{" "}
                      <Box component="i" className="fa-regular fa-bell" />
                    </em>
                  </MenuItem>
                </Select>
              </FormControl>
              <ContactScore contacts={bentoContacts} setAlert={setAlert} />
            </>
          ) : (
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: "none" }}
              onClick={() => setOpenAddNewContact(true)}
            >
              + Add Contact
            </Button>
          )}
        </Box>
      );
    }
  };

  return (
    <>
      {renderContacts()}

      {handleAddNewContact && (
        <AddNewContactDialog
          handleConfirm={handleConfirm}
          open={openAddNewContact}
          handleClose={handleCloseAddContactDialog}
          contact={contact}
          setContact={setContact}
        />
      )}

      <ViewScoreDialog
        open={openViewScore}
        setAlert={setAlert}
        handleClose={() => setOpenViewScore(false)}
      />
    </>
  );
}
