import {
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AlertContext } from "contexts/Alert";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import {
  EmailSequenceMap,
  SavedBrandCollection,
  SavedBrandStatus,
} from "schemas/dashboard";

import BulkSendDialog from "components/BulkSendDialog";
import { getCategoryName } from "utils/templates";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useCollection } from "utils/useCollection";
import { useDrafts } from "utils/useDrafts";

import CustomizationDialog from "./CustomizationDialog";
import styles from "./styles";

interface Props {
  handleCreateNewList: (bulkMove?: boolean) => void;
  setIsBulkMode: Dispatch<SetStateAction<boolean>>;
}

export default function MoveMenu({
  handleCreateNewList,
  setIsBulkMode,
}: Props) {
  const { moveDraftsFromOneListToAnother } = useDrafts();
  const { setAlert } = useContext(AlertContext);
  const {
    bulkBrands,
    setBulkBrands,
    bulkMoveTo,
    bulkDelete,
    moveLoading,
    setSelectAll,
  } = useContext(SavedBrandContext);
  const { collections, currentTab, selectedCollection } = useContext(
    SavedBrandCollectionsContext,
  );
  const { bulkMoveToSent } = useCollection();

  const [openCustomization, setOpenCustomization] = useState(false);
  const [openBulkSend, setOpenBulkSend] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const collectionsToMoveTo = Object.values(collections).filter(
    (x) => x.id !== selectedCollection?.id,
  );

  const handleBulkMove = async (collection: SavedBrandCollection) => {
    if (bulkBrands?.length === 0) {
      setAlert("Please select at least one brand", "warning");
      return;
    }
    trackEvent("Favorites List - Bulk Move To Button Pressed", {
      "Collection ID": collection.id,
    });
    handleClose();

    moveDraftsFromOneListToAnother(
      bulkBrands,
      collection.id,
      selectedCollection?.id || 0,
    );
    await bulkMoveTo(collection.id);
    _reset();
  };

  const handleBulkDelete = async () => {
    if (bulkBrands?.length === 0) {
      setAlert("Please select at least one brand", "warning");
      return;
    }
    trackEvent("Favorites List - Bulk Delete Button Pressed", {
      "Collection ID": selectedCollection?.id || 0,
    });
    setDeleteLoading(true);
    await bulkDelete();
    setDeleteLoading(false);
    _reset();
  };

  const _reset = () => {
    setBulkBrands([]);
    setIsBulkMode(false);
  };

  const createAndMove = () => {
    handleCreateNewList(true);
  };

  const writeMeDraft = () => {
    if (bulkBrands?.length === 0) {
      setAlert("Please select at least one brand", "warning");
      return;
    }
    trackEvent("Favorites List - Write Me Draft Button Clicked", {
      "Collection ID": selectedCollection?.id,
    });
    setOpenCustomization(true);
  };

  const sendAll = () => {
    if (bulkBrands?.length === 0) {
      setAlert("Please select at least one brand", "warning");
      return;
    }
    trackEvent("Favorites List - Send All Button Clicked", {
      "Collection ID": selectedCollection?.id,
    });
    setOpenBulkSend(true);
  };

  const resetSelectedBrands = () => {
    bulkMoveToSent(makeDeepCopy(bulkBrands));
    setIsBulkMode(false);
    setSelectAll(false);
    setBulkBrands([]);
  };

  const getEmailSequences = () => {
    const emailSequences: EmailSequenceMap = {};
    for (const brand of bulkBrands) {
      emailSequences[brand.id] = {
        bentoBrandId: brand.id,
        category: getCategoryName(brand),
        bentoBrand: brand,
      };
    }
    return emailSequences;
  };

  return (
    <>
      <Grid container sx={styles.moveTo}>
        <Grid container item xs justifyContent="center">
          <Tooltip title="Delete Selected Brands">
            <ButtonBase onClick={handleBulkDelete}>
              {deleteLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Box sx={styles.button}>
                  <Box
                    component="i"
                    className="fa-solid fa-trash"
                    sx={styles.icon}
                  />
                  Delete
                </Box>
              )}
            </ButtonBase>
          </Tooltip>
        </Grid>

        <Grid container item xs justifyContent="center">
          <Tooltip title="Move Selected Brands To New Collection">
            <ButtonBase onClick={handleClick}>
              {moveLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Box sx={styles.button}>
                  <Box
                    component="i"
                    className="fa-solid fa-folder-arrow-up"
                    sx={styles.icon}
                  />
                  Move To
                </Box>
              )}
            </ButtonBase>
          </Tooltip>
        </Grid>

        <Grid container item xs justifyContent="center">
          <Tooltip title="Write Me Drafts">
            <ButtonBase onClick={writeMeDraft}>
              <Box sx={styles.button}>
                <Box
                  component="i"
                  className="fa-solid fa-pen"
                  sx={styles.icon}
                />
                Drafts
              </Box>
            </ButtonBase>
          </Tooltip>
        </Grid>

        <Grid container item xs justifyContent="center">
          {currentTab === SavedBrandStatus.unsent && (
            <Tooltip title="Send All">
              <ButtonBase onClick={sendAll}>
                <Box sx={styles.button}>
                  <Box
                    component="i"
                    className="fa-solid fa-paper-plane-top"
                    sx={styles.icon}
                  />
                  Send All
                </Box>
              </ButtonBase>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {collectionsToMoveTo?.map((collection) => (
          <MenuItem
            key={collection.id}
            onClick={() => handleBulkMove(collection)}
          >
            {collection.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={createAndMove}>New List +</MenuItem>
      </Menu>
      <CustomizationDialog
        open={openCustomization}
        handleClose={() => setOpenCustomization(false)}
        setIsBulkMode={setIsBulkMode}
      />
      <BulkSendDialog
        open={openBulkSend}
        handleClose={() => setOpenBulkSend(false)}
        resetSelectedBrands={resetSelectedBrands}
        presetEmailSequenceMap={getEmailSequences()}
      />
    </>
  );
}
