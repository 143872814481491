import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { UserRequestsContext } from "contexts/UserRequests";
import { useContext } from "react";
import {
  CombinedRequest,
  RequestStatus,
  RequestType,
  SavedBrandStatus,
} from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { Map } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useRequests } from "utils/useRequests";

import SingleRequestCard from "./SingleRequestCard";
import styles from "./styles";

const UserRequestsList = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getType } = useRequests();

  // Contexts
  const { setDiscoverTab } = useContext(DiscoverViewContext);
  const { canMakeBrandRequest, setUpgradeDialogSource } =
    useContext(SubscriptionContext);
  const {
    setOpen,
    tab,
    setTab,
    total,
    setTotal,
    userRequests,
    sentRef,
    unsentRef,
    setUserRequests,
  } = useContext(UserRequestsContext);

  const closeDialog = () => {
    setDiscoverTab(null);
  };

  const deleteRequest = async (
    combinedRequest: CombinedRequest,
    index: number,
  ) => {
    if (!currentOrg) return;

    try {
      const type = getType(combinedRequest);
      let url = `/api/organization/${currentOrg.id}/`;
      const body: Map = { bentoBrandId: combinedRequest?.bentoBrand?.id };
      if (type === RequestType.brand_notifications) {
        url += `brand-notifications`;
      } else if (type === RequestType.contact_notifications) {
        url += `additional-contact-notifications`;
      } else {
        url += `user-requests`;
        body["description"] =
          combinedRequest?.bentoBrandIdOrUserRequestDescription;
        body["status"] = combinedRequest?.userRequestStatus;
      }

      await fetcherAuth(getToken, url, "DELETE", {}, body);
      setUserRequests(tab)((prev) => {
        const copy = makeDeepCopy(prev);
        copy.splice(index, 1);
        return copy;
      });
      setTotal((prev) => {
        const copy = makeDeepCopy(prev);
        copy[tab] = Math.max(copy[tab] - 1, 0);
        return copy;
      });
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const getLabel = (combinedRequest: CombinedRequest) => {
    if (
      combinedRequest.brandNotificationStatus === RequestStatus.found ||
      combinedRequest.additionalContactNotificationStatus ===
        RequestStatus.found
    ) {
      return "Contact Found";
    }
    if (combinedRequest.userRequestStatus === RequestStatus.found) {
      return "Brand Found";
    }
    return "Pending";
  };

  const openRequest = () => {
    trackEvent("Request Brand Clicked");
    if (!canMakeBrandRequest()) {
      setUpgradeDialogSource("Hits Brand Request Limit");
      return;
    }
    setOpen(true);
  };

  const handleChange = (e: CustomEvent, newValue: string) => {
    trackEvent("User Requests List - Tab Changed", {
      Tab: newValue,
    });
    setTab(newValue as SavedBrandStatus);
  };

  return (
    <>
      {!isMobileScreen && (
        <Grid item xs="auto" sx={styles.header}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ fontSize: 14 }}>
              REQUESTS
            </Typography>
            <IconButton sx={styles.close} onClick={closeDialog}>
              <Box component="i" className="fa-regular fa-xmark" />
            </IconButton>
          </Grid>
        </Grid>
      )}

      <Tabs value={tab} onChange={handleChange} sx={styles.tabContainer}>
        <Tab
          sx={styles.tab}
          value={SavedBrandStatus.unsent}
          label={
            <>
              Unsent{" "}
              {total.unsent === undefined ? (
                <CircularProgress size={10} />
              ) : (
                <>({total.unsent})</>
              )}{" "}
            </>
          }
        />
        <Tab
          sx={styles.tab}
          value={SavedBrandStatus.sent}
          label={
            <>
              Sent{" "}
              {total.sent === undefined ? (
                <CircularProgress size={10} />
              ) : (
                <>({total.sent})</>
              )}
            </>
          }
        />
      </Tabs>

      <Grid container sx={styles.container}>
        {userRequests(tab)?.map((request, index) => (
          <SingleRequestCard
            request={request}
            bentoBrand={request?.bentoBrand}
            requestWebsiteLink={request?.bentoBrandIdOrUserRequestDescription}
            onDelete={() => deleteRequest(request, index)}
            status={
              request.additionalContactNotificationStatus ||
              request.brandNotificationStatus ||
              request.userRequestStatus ||
              RequestStatus.pending
            }
            label={getLabel(request)}
            ref={tab === SavedBrandStatus.sent ? sentRef : unsentRef}
            displayedLength={userRequests(tab)?.length}
            index={index}
            key={index}
          />
        ))}
      </Grid>

      <Grid
        container
        justifyContent="center"
        sx={isMobileScreen ? {} : styles.footer}
      >
        <Box sx={styles.newRequest} onClick={openRequest}>
          <strong>New Request + </strong>
        </Box>
      </Grid>
    </>
  );
};

export default UserRequestsList;
