import { Box, Grid, IconButton, TableCell, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ContactViewContext } from "contexts/ContactView";
import { TrackingColumnsContext } from "contexts/TrackingColumns";
import { useContext, useState } from "react";
import { AutomatedContactProperty } from "schemas/dashboard";

import {
  COLUMN_NAME,
  HEADER_COLUMN_MENU_OPENED,
} from "features/Influencer/Tracking/events";
import {
  ContactViewProperty,
  EMAIL_COLUMN,
  NotEditableFields,
  PropertySortDirection,
} from "features/Influencer/Tracking/schema";

import CustomColumnDialog from "../AddColumn/CustomColumn";
import HeaderMenu from "./HeaderMenu";
import { getStyles } from "./helpers";
import styles from "./styles";

const DraggableTableHeader = ({ prop }: { prop: ContactViewProperty }) => {
  const { trackTable, selectedView } = useContext(ContactViewContext);
  const { getTitleForHeader } = useContext(TrackingColumnsContext);

  const name = prop?.contactPropertyName || prop?.contactProperty?.name;
  const [openEditColumn, setOpenEditColumn] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    trackTable(HEADER_COLUMN_MENU_OPENED, {
      [COLUMN_NAME]: name,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const readOnly =
    selectedView?.name === "Imports" &&
    prop.contactProperty?.automatedProperty === AutomatedContactProperty.stage;

  const wand = (
    <Tooltip title="Bento auto-fills this column for you">
      <Box
        component="i"
        className="fa-solid fa-wand-magic-sparkles"
        sx={{ color: readOnly ? grey[500] : "#2362C1", mr: 1 }}
      />
    </Tooltip>
  );

  const hasIcons = [
    "smart_task",
    "latest_sequence_status",
    "latest_outreach_template",
    "last_email_open_count",
  ];
  const notEdit = name && NotEditableFields?.includes(name);
  const isSticky = prop?.contactPropertyName === EMAIL_COLUMN;

  const getToolTip = () => {
    if (name === "last_gmail_message_date") {
      return "Last Interaction Date";
    } else if (notEdit) {
      return "These fields are not editable because they are automated by Bento";
    } else if (readOnly) {
      let str = `This field is read only on the ${selectedView.name} tab`;
      if (
        prop.contactProperty?.automatedProperty ===
        AutomatedContactProperty.stage
      ) {
        str += `. Use the "Move Brand To" button to change Stage`;
      }
      return str;
    } else {
      return "";
    }
  };

  const isAutomated = Boolean(prop?.contactProperty?.automatedProperty);
  const displayWand = (name && hasIcons?.includes(name)) || isAutomated;
  const hasSort = Boolean(
    selectedView?.contactViewProperties?.find((x) => x.sortDirection !== null),
  );

  return (
    <>
      <Tooltip title={getToolTip()}>
        <TableCell
          // @ts-ignore
          style={styles.tableCell(isSticky)}
          sx={getStyles(prop, hasSort)}
          onClick={handleClick}
        >
          <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            alignItems="center"
            gap={1}
          >
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              item
              xs
              sx={[readOnly && styles.readOnly]}
            >
              {displayWand && wand} {getTitleForHeader(name)}{" "}
              {prop.sortDirection === PropertySortDirection.asc ? (
                <Box
                  sx={{ ml: 1 }}
                  component="i"
                  className="fa-thin fa-arrow-up-short-wide"
                />
              ) : prop.sortDirection === PropertySortDirection.desc ? (
                <Box
                  sx={{ ml: 1 }}
                  component="i"
                  className="fa-thin fa-arrow-down-short-wide"
                />
              ) : (
                <></>
              )}
              {prop?.filters?.length > 0 && (
                <Box
                  sx={{ ml: 1 }}
                  component="i"
                  className="fa-thin fa-filter"
                />
              )}
            </Grid>
            <Grid item xs={1}>
              <IconButton sx={styles.icon}>
                <Box component="i" className="fa-thin fa-ellipsis-vertical" />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </Tooltip>
      {prop && (
        <>
          <HeaderMenu
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            prop={prop}
            setOpenEditColumn={setOpenEditColumn}
          />
          {openEditColumn && (
            <CustomColumnDialog
              open={openEditColumn}
              handleClose={() => setOpenEditColumn(false)}
              editedProperty={prop?.contactProperty}
            />
          )}
        </>
      )}
    </>
  );
};

export default DraggableTableHeader;
