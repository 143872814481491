import { Box, IconButton, Typography } from "@mui/material";
import { PreviewMessage, SendOption } from "schemas/dashboard";

import { DialogScreen } from "../schema";

interface Props {
  editList: string;
  sendOption: SendOption | undefined;
  totalBrands: number;
  outreachMessages: PreviewMessage[];
  handleBack: (screen: DialogScreen) => void;
}
const MainDialogHeader = ({
  editList,
  sendOption,
  totalBrands,
  outreachMessages,
  handleBack,
}: Props) => {
  const getTitle = () => {
    if (outreachMessages?.length > 0) {
      return { title: "Preview Email", screen: DialogScreen.preview_email };
    } else if (editList) {
      return {
        title: `Edit ${editList} List`,
        screen: DialogScreen.edit_brands_list,
      };
    } else {
      return {
        title: `Schedule ${totalBrands} Emails`,
        screen: DialogScreen.send_option,
      };
    }
  };

  return (
    <Typography variant="h6" sx={{ mb: 3 }} component="div">
      {sendOption && (
        <IconButton
          onClick={() => handleBack(getTitle()?.screen)}
          sx={{ ml: -1 }}
        >
          <Box
            component="i"
            className="fa-regular fa-arrow-left"
            sx={{ fontSize: 16 }}
          />
        </IconButton>
      )}
      {getTitle()?.title}
    </Typography>
  );
};

export default MainDialogHeader;
