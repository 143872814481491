import {
  Box,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { MessageStatus, OutreachContact } from "schemas/dashboard";
import { CustomEvent, SetAlertType } from "schemas/functions";

import { sanitize } from "utils/sanitize";
import { hideEmail } from "utils/string";
import { formatEpochTimestamp } from "utils/time";
import { trackEvent } from "utils/tracking";

import { TemplateContentType } from "../../schema";
import IconAction from "./components/IconAction";
import styles from "./styles";

interface Props {
  contact: OutreachContact;
  setAlert: SetAlertType;
}

const ScheduledContactRow = ({ contact, setAlert }: Props) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isTinyScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const openEmailDrawer = (event: CustomEvent, contact: OutreachContact) => {
    event.stopPropagation();
    trackEvent("Inbox Table Row Clicked", {
      contactId: contact?.id,
      lastGmailThreadId: contact.lastGmailThreadId,
    });

    const isScheduled =
      !contact.latestSequenceSentCount && contact.latestSequenceNextSendAt;

    let url = contact?.lastGmailThreadId
      ? `${contact.id}/email/${contact.lastGmailThreadId}`
      : isScheduled
        ? `${contact.id}/scheduled`
        : `${contact.id}/email`;

    if (!isTinyScreen) {
      navigate(url);
    } else {
      navigate(`${contact.id}`);
    }
  };

  const earliestPendingEmail = contact.emails?.find(
    (email) => email.status === MessageStatus.PENDING,
  );

  const participants =
    contact.lastGmailThreadParticipants ||
    `To: ${
      contact.contactName?.split(" ")?.[0] ||
      contact.email?.split("@")?.[0] ||
      ""
    }`;

  const snippet = `${
    earliestPendingEmail?.body
      ? sanitize(earliestPendingEmail.body)
          .replace(/<\/?[^>]*>/g, " ")
          .substring(0, 170)
      : ""
  }...`;

  const subject = earliestPendingEmail
    ? earliestPendingEmail?.subject
    : contact?.lastGmailMessageSubject?.replace(/(<([^>]+)>)/gi, "");

  const formattedDate = formatEpochTimestamp({
    epochTimestamp: Number(earliestPendingEmail?.sendAt),
    fullDate: true,
  });

  const statusChip =
    earliestPendingEmail?.contentType === TemplateContentType.OUTREACH ||
    earliestPendingEmail?.contentType === TemplateContentType.INBOX ? (
      <Chip
        size="small"
        sx={[
          styles.scheduledChip,
          {
            backgroundColor: "primary.light",
          },
        ]}
        label="Scheduled"
      />
    ) : earliestPendingEmail?.contentType === TemplateContentType.REPLY ? (
      <Chip
        size="small"
        sx={[
          styles.scheduledChip,
          {
            backgroundColor: blue[50],
          },
        ]}
        label="Reply Scheduled"
      />
    ) : (
      <Chip
        size="small"
        sx={[
          styles.scheduledChip,
          {
            backgroundColor: "secondary.light",
          },
        ]}
        label="Follow-up Scheduled"
      />
    );

  return (
    <Grid
      container
      alignItems="center"
      columnSpacing={1}
      onClick={(event) => openEmailDrawer(event, contact)}
      sx={[styles.accordion, contact.isPinned ? styles.rowPinned : {}]}
    >
      {isSmallScreen && (
        <>
          <Box sx={styles.mobilePinned}>
            <IconAction
              fieldName="isPinned"
              contact={contact}
              setAlert={setAlert}
            />
          </Box>
          <Grid item xs={12} container columnSpacing={1}>
            <Grid item xs>
              <Typography component="div" sx={styles.mobileTitle}>
                <Typography
                  component="span"
                  sx={{
                    mr: 1,
                    fontWeight: contact.isPinned ? 700 : "inherit",
                  }}
                >
                  {contact?.brand?.name}
                </Typography>
                {statusChip}
              </Typography>
              <Grid container alignItems="center">
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  sx={{ mr: 1, mb: 0.5 }}
                >
                  <Typography component="div" sx={styles.participants}>
                    {participants}
                  </Typography>
                  {contact.lastGmailThreadLength &&
                    contact.lastGmailThreadLength > 1 && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={styles.lastThreadLength}
                      >
                        {contact.lastGmailThreadLength}
                      </Typography>
                    )}
                </Grid>
              </Grid>
              <Typography sx={styles.subject} gutterBottom component="div">
                {subject}
              </Typography>
              <Typography sx={styles.email} gutterBottom component="div">
                Scheduled For: {formattedDate}
              </Typography>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{
                  __html: snippet,
                }}
                sx={styles.snippet}
              ></Typography>
            </Grid>
          </Grid>
        </>
      )}
      {!isSmallScreen && (
        <>
          <Grid item md="auto">
            <IconAction
              fieldName="isPinned"
              contact={contact}
              setAlert={setAlert}
            />
          </Grid>
          <Grid item md="auto">
            <Box sx={styles.nameWrapper}>
              <Box
                component="div"
                sx={{ fontWeight: contact.isPinned ? 700 : "inherit" }}
              >
                {contact?.brand?.name}
              </Box>

              <Typography sx={styles.email}>{contact?.contactName}</Typography>
              <Typography sx={styles.email}>{contact?.contactTitle}</Typography>
              <Typography sx={styles.email}>
                {hideEmail(contact.email || "")}
              </Typography>
            </Box>
          </Grid>
          <Grid item md>
            <Box component="div">
              <Grid container alignItems="center">
                <Grid
                  item
                  container
                  alignItems="center"
                  xs="auto"
                  sx={{ mr: 1, mb: 0.5 }}
                >
                  <Typography component="div" sx={styles.participants}>
                    {participants}
                  </Typography>
                  {contact.lastGmailThreadLength &&
                    contact.lastGmailThreadLength > 1 && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={styles.lastThreadLength}
                      >
                        {contact.lastGmailThreadLength}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs="auto">
                  {statusChip}
                </Grid>
              </Grid>
            </Box>
            <Typography sx={styles.subject}>{subject}</Typography>

            <Typography
              dangerouslySetInnerHTML={{
                __html: snippet,
              }}
              sx={styles.snippet}
            ></Typography>
          </Grid>

          <Grid item md="auto">
            <Typography
              variant="caption"
              color="textSecondary"
              sx={styles.formattedDate}
              component="div"
            >
              Scheduled For: {formattedDate}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default ScheduledContactRow;
