import { pageStyles } from "components/Styled/Page/styles";
import { tableStyles } from "components/Styled/Table/sharedStyles";

const styles = {
  ...pageStyles,
  ...tableStyles,
  button: {
    fontSize: 14,
    color: "primary.main",
  },
  root: {
    pb: "60px",
  },
};

export default styles;
