import { lighten } from "@mui/material";
import { HOME_BODY_COLOR } from "styles/oneOffColor";

const styles = {
  pageHeader: {
    position: "absolute",
    top: 0,
    right: 0,
    p: 4,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  userImage: {
    width: 45,
    height: 45,
    cursor: "pointer",
  },
  body: {
    backgroundColor: HOME_BODY_COLOR,
    position: "absolute",
    overflowY: "auto",
    left: 0,
    right: 0,
    bottom: 0,
    top: "60px",
  },
  wrapper: {
    height: "100%",
    position: "relative",
    ml: { lg: 1, xs: 1 },
    flexGrow: 1,
  },
  edit: {
    mr: 1,
    backgroundColor: "primary.main",
    width: 18,
    height: 18,
    color: "white",
    fontSize: 10,
    "&:hover": {
      backgroundColor: `${lighten("#151e16", 0.2)}`,
    },
  },
};

export default styles;
