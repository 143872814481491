import { BuiltInContactPropertyName } from "schemas/dashboard";
import { Map } from "schemas/functions";

import { ContactViewProperty } from "features/Influencer/Tracking/schema";

import styles from "./styles";

export const getZIndex = (isSticky: boolean) => {
  if (isSticky) return 5;
  else return 4;
};

export const getStyles = (prop?: ContactViewProperty, hasSort?: boolean) => {
  if (!prop) return [styles.th, styles.normalBg];
  const arr: Map[] = [styles.th];
  if (prop?.filters?.length > 0) {
    arr.push(styles.filterColor);
  } else if (
    prop?.sortDirection ||
    (!hasSort &&
      prop?.contactPropertyName === BuiltInContactPropertyName.createdAt)
  ) {
    arr.push(styles.sortColor);
  } else {
    arr.push(styles.normalBg);
  }
  return arr;
};
