import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { BentoBrand, SendOption } from "schemas/dashboard";
import { ALL } from "schemas/forms";

import { Template } from "features/Influencer/ContactList/schema";
import { makeDeepCopy } from "utils/updateLocalState";

import PickSendOption from "../PickSendOption";
import TemplatePerCategory from "../TemplatePerCategory";
import EditBrandList from "../TemplatePerCategory/EditBrandList";
import { CategoryMap } from "../schema";
import UsePreviousTemplateToggle from "./UsePreviousTemplateToggle";
import styles from "./styles";

interface Props {
  editList: string;
  setEditList: Dispatch<SetStateAction<string>>;
  sendOption?: SendOption;
  renderTimePickedOption: () => JSX.Element;
  categories: CategoryMap;
  setCategories: Dispatch<SetStateAction<CategoryMap>>;
  pickOption: (option: SendOption) => void;
  fromTrackingTable?: boolean;
  lastUsedTemplateSelected: boolean;
  setLastUsedTemplateSelected: Dispatch<SetStateAction<boolean>>;
  previewTemplate: (templates: Template[], bentoBrand: BentoBrand) => void;
}
const MainDialogBody = ({
  editList,
  setEditList,
  sendOption,
  renderTimePickedOption,
  categories,
  setCategories,
  pickOption,
  lastUsedTemplateSelected,
  setLastUsedTemplateSelected,
  previewTemplate,
  fromTrackingTable = false,
}: Props) => {
  const updateEmailSequencesByCategories = (
    category: string,
    templates: Template[],
  ) => {
    setCategories((prev) => {
      const copy = makeDeepCopy(prev);
      copy[category].fallbackTemplates = templates;
      return copy;
    });
  };

  if (editList) {
    const bentoBrands = categories[editList]?.emailSequences?.map(
      (es) => es.bentoBrand,
    );
    return (
      <EditBrandList
        bentoBrands={bentoBrands}
        category={editList}
        setCategories={setCategories}
      />
    );
  } else if (sendOption === SendOption.same) {
    return (
      <>
        <Grid container justifyContent="space-between">
          <Box>
            <strong> Select Templates</strong>
            <UsePreviousTemplateToggle
              fromTrackingTable={fromTrackingTable}
              lastUsedTemplateSelected={lastUsedTemplateSelected}
              setLastUsedTemplateSelected={setLastUsedTemplateSelected}
            />
          </Box>

          <Tooltip title="Preview templates">
            <IconButton
              sx={{ fontSize: 14, color: "black" }}
              onClick={() =>
                previewTemplate(
                  categories[ALL]?.fallbackTemplates,
                  categories[ALL]?.emailSequences?.[0]?.bentoBrand,
                )
              }
            >
              <Box component="i" className="fa-regular fa-eye" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Typography variant="body2" sx={[styles.subtitle, { mb: 2 }]}>
          {lastUsedTemplateSelected
            ? "If brand has no previous templates, we will default to the templates you select below."
            : "Bento will automatically populate your outreach emails using your templates."}
        </Typography>

        {categories[ALL]?.emailSequences && (
          <TemplatePerCategory
            category={ALL}
            emailSequences={categories[ALL]?.emailSequences}
            fallbackTemplates={categories[ALL]?.fallbackTemplates}
            updateEmailSequencesByCategories={updateEmailSequencesByCategories}
            setEditList={setEditList}
            previewTemplate={previewTemplate}
          />
        )}
        {renderTimePickedOption()}
      </>
    );
  } else if (sendOption === SendOption.by_category) {
    return (
      <>
        <Divider sx={{ my: 2, mx: -3 }} />
        <strong>Select Templates By Category</strong>
        <UsePreviousTemplateToggle
          fromTrackingTable={fromTrackingTable}
          lastUsedTemplateSelected={lastUsedTemplateSelected}
          setLastUsedTemplateSelected={setLastUsedTemplateSelected}
        />
        {Object.keys(categories).map(
          (category) =>
            categories[category]?.emailSequences && (
              <TemplatePerCategory
                category={category}
                emailSequences={categories[category]?.emailSequences}
                fallbackTemplates={categories[category]?.fallbackTemplates}
                updateEmailSequencesByCategories={
                  updateEmailSequencesByCategories
                }
                key={category}
                setEditList={setEditList}
                previewTemplate={previewTemplate}
              />
            ),
        )}
        <Divider sx={{ my: 2, mx: -3 }} />
        {renderTimePickedOption()}
      </>
    );
  } else {
    return <PickSendOption sendOption={sendOption} pickOption={pickOption} />;
  }
};

export default MainDialogBody;
