export const LANDING_PAGE_LINK = "https://www.onbento.com/";
export const BENTO_RESOURCES_GUIDE_LINK =
  "https://hatchways.notion.site/Bento-Resource-Guide-b43c00e8980347fca5de92cd71c51818";
export const UNSPECIFIED = "unspecifed";
export const TERMS_AND_CONDITIONS_LINK =
  "https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/Updated%20Jan%2013%2C%202025-%20Risk-Free%20Virtual%20Assistant%20Program.pdf";
export const VA_MONEY_BACK_DEAL = 600;
export const BENTO_VA_ONBOARDING_CALENDLY_LINK =
  "https://calendly.com/jaclyn-bento/virtual-assistant-onboarding";
export const CLOSE_VA_ANNOUNCEMENT = "close_va_announcement";
export const VA_LANDING_PAGE_LINK = "https://www.onbento.com/va";
