import { Box, MenuItem } from "@mui/material";
import { OrganizationUserContext } from "contexts/Organization";
import lodash from "lodash";
import { Dispatch, SetStateAction, useContext } from "react";
import { LOCATIONS, Location, SearchParams } from "schemas/dashboard";

import DropdownSelect from "components/DropdownSelect";
import LocationAutocomplete from "components/LocationAutocomplete";
import { UserLocation } from "components/LocationAutocomplete/schema";
import { useAutoCompletePrediction } from "components/LocationAutocomplete/useAutoCompletePrediction";
import { useSearchBrand } from "utils/useSearchBrand";

import styles from "./styles";

interface Props {
  handleAddOption: (name: string, value: string) => void;
  close: boolean;
  setClose: Dispatch<SetStateAction<boolean>>;
  selectedOptionIcon: JSX.Element;
  isOptionSelected: (key: string, params: string) => boolean;
}

export default function LocationFilter({
  handleAddOption,
  close,
  setClose,
  selectedOptionIcon,
  isOptionSelected,
}: Props) {
  const { addCategoryQueryToSearch, handleAddGeopoint } = useSearchBrand();
  const { profile } = useContext(OrganizationUserContext);
  const { setInputValue } = useAutoCompletePrediction(handleAddGeopoint);

  const name = SearchParams.LOCATION_DISCOVER;

  const addCityToSearch = () => {
    if (profile?.latitude && profile?.longitude && profile?.location) {
      handleAddGeopoint({
        country_code: profile.location,
        latitude: profile.latitude,
        longitude: profile.longitude,
        label: profile?.city || "",
      });
    } else if (profile?.city) {
      // If user do not have longitude or latitude associated with their city,
      // use Google Maps Autocomplete to find it.
      setInputValue(profile?.city);
    }
    setClose(true);
  };

  const handleLocationChange = (userLocation: UserLocation) => {
    const addressComponents = userLocation?.address_components;

    let city = addressComponents?.find(
      (x) =>
        x?.types?.includes("administrative_area_level_3") ||
        x?.types?.includes("locality"),
    )?.longText;

    // State, province, etc.
    let region = addressComponents?.find((x) =>
      x?.types?.includes("administrative_area_level_1"),
    )?.longText;

    const country = addressComponents?.find((x) =>
      x.types?.includes("country"),
    )?.longText;
    const categoryLocation = LOCATIONS?.find(
      (x) => x.name === lodash.startCase(country),
    );

    if (categoryLocation && !city && !region) {
      addCategoryQueryToSearch(
        SearchParams.LOCATION_DISCOVER,
        categoryLocation.key,
      );
      return;
    }

    if (!city && !region) {
      city = userLocation?.address_components?.[0]?.longText;
    }

    if (
      userLocation?.country_code &&
      userLocation.latitude &&
      userLocation.longitude
    ) {
      handleAddGeopoint({
        country_code: userLocation?.country_code,
        latitude: userLocation?.latitude,
        longitude: userLocation?.longitude,
        label: city || region,
      });
      setClose(true);
    }
  };

  return (
    <DropdownSelect
      label={"Location"}
      id={"location"}
      close={close}
      setClose={setClose}
    >
      <Box sx={{ px: 2 }} onClick={(e) => e.stopPropagation()}>
        <LocationAutocomplete
          handleLocationChange={handleLocationChange}
          customStyling={styles.search}
          placeholderText="Beverly Hills"
        />
      </Box>
      <MenuItem
        key={Location.All}
        value={Location.All}
        onClick={() => handleAddOption(name, Location.All)}
      >
        {isOptionSelected(Location.All, SearchParams.LOCATION_DISCOVER) &&
          selectedOptionIcon}{" "}
        All
      </MenuItem>
      {profile?.city && profile?.city?.length > 0 && (
        <MenuItem value={profile?.city} onClick={() => addCityToSearch()}>
          📍 {profile?.city}
        </MenuItem>
      )}

      {LOCATIONS?.filter((x) => x.key !== Location.All)?.map((location) => (
        <MenuItem
          key={location.key}
          value={location.key}
          onClick={() => handleAddOption(name, location.key)}
        >
          {isOptionSelected(location.key, SearchParams.LOCATION_DISCOVER) &&
            selectedOptionIcon}{" "}
          {location.name}
        </MenuItem>
      ))}
    </DropdownSelect>
  );
}
