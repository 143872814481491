import { Box, Grid, IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { SavedBrandContext } from "contexts/SavedBrand";
import { useContext } from "react";
import { SavedBrandCollection } from "schemas/dashboard";
import { HEART_COLOR } from "styles/oneOffColor";

import styles from "../styles";

interface Props {
  collection: SavedBrandCollection;
  setSelectedCollection: () => void;
}

const Collection = ({ collection, setSelectedCollection }: Props) => {
  const collectionId = collection.id;
  const collectionName = collection.name;
  const { brandBeingSaved } = useContext(SavedBrandContext);

  const getActionText = () => {
    if (brandBeingSaved && collectionId === 0) {
      return (
        <Grid sx={{ px: 2 }}>
          Saved{" "}
          <Box
            component="i"
            className="fa-solid fa-bookmark"
            sx={{ color: HEART_COLOR, ml: 1 }}
          />
        </Grid>
      );
    } else {
      return (
        <Box>
          <IconButton sx={styles.circlePlus}>
            <Box
              sx={styles.chevron}
              component="i"
              className="fa-regular fa-arrow-right"
            />
          </IconButton>
        </Box>
      );
    }
  };

  return (
    <Grid key={`${collectionId}-${collectionName}`}>
      <Grid
        container
        alignItems="center"
        sx={styles.collection}
        justifyContent="space-between"
        onClick={setSelectedCollection}
      >
        <Box>
          {collectionName}

          <Typography variant="body2" sx={{ color: grey[600] }}>
            Unsent ({collection.unsentCount}) | Sent ({collection.sentCount})
          </Typography>
        </Box>
        {getActionText()}
      </Grid>
    </Grid>
  );
};

export default Collection;
