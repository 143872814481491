import { camelCase } from "lodash";
import moment from "moment";
import { OutreachContact, PropertyType } from "schemas/dashboard";
import { Map } from "schemas/functions";

import OpenedColumn from "components/OpenedColumn";

import {
  ContactView,
  ContactViewProperty,
  SelectOption,
} from "../../../schema";
import MoveBrandToButton from "./components/MoveBrandToButton";
import TableAction from "./components/TableAction";
import TableAttachment from "./components/TableAttachment";
import TableButton from "./components/TableButton";
import TableCheckbox from "./components/TableCheckbox";
import TableEmailThread from "./components/TableEmailThread";
import TableNotes from "./components/TableNotes";
import TableSelect from "./components/TableSelect";
import TableStatus from "./components/TableStatus";
import TableTemplateUsed from "./components/TableTemplateUsed";
import TaskCell from "./components/TaskCell";

export const TitleSize: { [key: string]: any } = {
  lastGmailMessageDate: 50,
};

export const getKeyAndValueDefault = (
  property: ContactViewProperty,
  outreachContact: OutreachContact,
) => {
  const key = camelCase(property?.contactPropertyName);
  let value: any = "";
  if (key === "lastGmailMessageDate") {
    value = outreachContact?.lastGmailMessageDate;
    if (typeof value === "number") {
      value = moment(value * 1000).format("MMM DD, YYYY");
    }
  } else if (key === "createdAt" && outreachContact?.createdAt) {
    value = moment(outreachContact?.createdAt * 1000).format("MMM DD, YYYY");
  } else if (key === "brandName") {
    value = outreachContact?.brand?.name;
  } else if (key === "brandHasResponded") {
    value = outreachContact?.brand?.hasResponded;
  } else if (
    key === "brandLastGmailMessageDate" &&
    outreachContact?.brand?.lastGmailMessageDate
  ) {
    value = moment(outreachContact?.brand?.lastGmailMessageDate * 1000).format(
      "MMM DD, YYYY",
    );
  } else {
    value = outreachContact[key as keyof OutreachContact];
  }
  return { key, value };
};

export const getCustomPropertyValue = (
  property: ContactViewProperty,
  outreachContact: OutreachContact,
) => {
  const id = property?.contactPropertyId;
  const cProps = outreachContact?.propertyValues;
  const type = property.contactProperty?.type;
  let value = type === PropertyType.multipleSelect ? [] : "";
  if (id && cProps && id in cProps && cProps[id]) {
    value = cProps[id];
  }
  return value;
};

export const getDefaultValue = (
  property: ContactViewProperty,
  outreachContact: OutreachContact,
  goToThread: (
    url: string,
    name: string,
    openBlank?: boolean,
    properties?: Map,
  ) => void,
  ref: any,
  showTextField: boolean,
) => {
  const { key, value } = getKeyAndValueDefault(property, outreachContact);
  const isScheduled =
    !outreachContact.latestSequenceSentCount &&
    outreachContact.latestSequenceNextSendAt;

  let url = outreachContact?.lastGmailThreadId
    ? `${outreachContact?.id}/email/${outreachContact?.lastGmailThreadId}`
    : isScheduled
      ? `${outreachContact?.id}/scheduled`
      : `${outreachContact?.id}/email`;

  if (
    key === "lastGmailMessageDate" ||
    key === "createdAt" ||
    key === "brandLastGmailMessageDate"
  ) {
    return (
      <TableNotes
        notes={value}
        outreachContact={outreachContact}
        property={property}
        type={PropertyType.shortText}
        ref={ref}
        showTextField={showTextField}
      />
    );
  } else if (key === "smartTask") {
    return (
      <TaskCell
        outreachContact={outreachContact}
        property={property}
        ref={ref}
      />
    );
  } else if (key === "isImportant" || key === "brandHasResponded") {
    return (
      <TableCheckbox
        outreachContact={outreachContact}
        isChecked={value}
        property={property}
        isCustom={false}
        ref={ref}
      />
    );
  } else if (key === "emailThread" && outreachContact?.email) {
    return (
      <TableEmailThread
        onClick={() =>
          goToThread(url, "Inbox Page Link Clicked", false, {
            "Gmail Thread Length": outreachContact?.lastGmailThreadLength,
            "Gmail Participants": outreachContact?.lastGmailThreadParticipants,
            "Clicked From": "Tracking table email thread",
          })
        }
        lastGmailThreadLength={outreachContact?.lastGmailThreadLength}
        lastGmailThreadParticipants={
          outreachContact?.lastGmailThreadParticipants
        }
      />
    );
  } else if (
    key === "sendFollowUp" &&
    outreachContact?.email &&
    outreachContact?.lastGmailThreadId
  ) {
    return (
      <TableButton
        onClick={() =>
          goToThread(
            `${url}?display_followup_templates=${true}`,
            "Send Follow-Up Button Clicked",
          )
        }
        text="Draft"
        ref={ref}
      />
    );
  } else if (key === "actions") {
    return (
      <TableAction
        property={property}
        outreachContact={outreachContact}
        goToThread={goToThread}
      />
    );
  } else if (key === "moveBrandTo") {
    return <MoveBrandToButton outreachContact={outreachContact} />;
  } else if (key === "latestSequenceStatus") {
    return <TableStatus outreachContact={outreachContact} />;
  } else if (key === "latestOutreachTemplate") {
    return <TableTemplateUsed outreachContact={outreachContact} />;
  } else if (key === "lastEmailOpenCount") {
    return <OpenedColumn contact={outreachContact} onTrackingPage />;
  } else {
    return (
      <TableNotes
        notes={value?.toString() || ""}
        outreachContact={outreachContact}
        property={property}
        type={PropertyType.shortText}
        ref={ref}
        showTextField={showTextField}
      />
    );
  }
};

export const renderCustomProperty = (
  property: ContactViewProperty,
  outreachContact: OutreachContact,
  ref: any,
  showTextField: boolean,
) => {
  const noteFields = [
    PropertyType.longText,
    PropertyType.shortText,
    PropertyType.number,
    PropertyType.date,
    PropertyType.dateTime,
  ];
  const type = property?.contactProperty?.type;
  if (!type) {
    return <></>;
  }
  const value = getCustomPropertyValue(property, outreachContact);
  if (
    type === PropertyType.multipleSelect ||
    type === PropertyType.singleSelect
  ) {
    return (
      <TableSelect
        property={property}
        value={value}
        outreachContact={outreachContact}
        type={type}
        options={property?.contactProperty?.selectOptions}
        ref={ref}
      />
    );
  } else if (
    noteFields?.includes(type) &&
    (typeof value === "string" || typeof value === "number")
  ) {
    return (
      <TableNotes
        notes={value}
        outreachContact={outreachContact}
        property={property}
        type={type}
        ref={ref}
        showTextField={showTextField}
      />
    );
  } else if (type === PropertyType.checkbox) {
    return (
      <TableCheckbox
        outreachContact={outreachContact}
        isChecked={Boolean(value)}
        property={property}
        isCustom={true}
        ref={ref}
      />
    );
  } else if (type === PropertyType.attachment) {
    return (
      <TableAttachment
        outreachContact={outreachContact}
        property={property}
        value={value}
        ref={ref}
      />
    );
  } else {
    return <></>;
  }
};

export const widthByType: { [key: string]: number } = {
  [PropertyType.number]: 50,
  [PropertyType.shortText]: 200,
  [PropertyType.longText]: 200,
  [PropertyType.date]: 100,
  [PropertyType.dateTime]: 150,
};

export const getChipNameFromOptions = (v: string, options: SelectOption[]) => {
  return options?.find((x: any) => x.id === v);
};

export const getValueOnAutomatedField = (
  selectedView: ContactView,
  outreachContact: OutreachContact,
) => {
  // Returns a list of values that the user currently have for their automated fields
  const fields: { [key: string]: string[] } = {};
  if (selectedView?.contactViewProperties) {
    for (const prop of selectedView?.contactViewProperties) {
      if (prop?.contactProperty?.automatedProperty) {
        const id = prop?.contactPropertyId;
        const value = id ? outreachContact?.propertyValues?.[id] : "";
        if (value) {
          const options = prop?.contactProperty?.selectOptions;
          if (Array.isArray(value)) {
            const matchedArray = value?.map((v) =>
              getChipNameFromOptions(v, options || []),
            );
            fields[prop?.contactProperty?.name] = matchedArray?.map((x) =>
              x ? x.name : "",
            );
          } else {
            const matched = getChipNameFromOptions(value, options || []);
            if (matched?.name)
              fields[prop?.contactProperty?.name] = [matched?.name];
          }
        }
      }
    }
  }
  return fields;
};
