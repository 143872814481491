import { grey } from "@mui/material/colors";
import { HOME_BODY_COLOR } from "styles/oneOffColor";

const styles = {
  mobileNavigation: {
    display: "flex",
    alignItems: "center",
    pl: 0,
    pr: 2,
    height: 60,
    backgroundColor: HOME_BODY_COLOR,
    borderBottom: "none",
  },
  navigation: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    pl: 4,
    pr: 2,
    height: 60,
    backgroundColor: HOME_BODY_COLOR,
    borderBottom: "none",
  },
  menuButton: {
    fontSize: { xs: 12, md: 14 },
    px: 2,
  },
  activeMenuButton: {
    backgroundColor: "green.main",
    color: "white",
    "&:hover": {
      backgroundColor: "green.light",
    },
  },
  avatar: {
    backgroundColor: "primary.main",
    color: "white",
    fontSize: { xs: 12, md: 18 },
    width: { xs: 30, md: 40 },
    height: { xs: 30, md: 40 },
  },
  icon: {
    color: "green.dark",
  },
  arrow: {
    color: "green.dark",
    ml: 1,
    fontSize: { xs: 12, md: 14 },
  },
  menuBarIcon: { color: "green.dark", fontSize: 16 },
  iconButton: { ml: 1, mr: 0, color: "green.dark", fontSize: 12 },
  bell: { color: "green.dark", fontSize: 18 },
  totalMessages: { color: grey[500] },
  sent: { color: "primary.main" },
  emailLeft: { fontSize: 10, color: grey[400], textTransform: "none" },
  emailsButton: { display: "block", textAlign: "center" },
  topBarLogo: { width: 50, mr: 2, cursor: "pointer" },
};

export default styles;
