import { SelectMenuItem } from "contexts/TrackingColumns";

import {
  BuiltInContactPropertyNames,
  ContactViewProperty,
  CustomFilterColumns,
  DateFilterColumns,
  FilterType,
} from "features/Influencer/Tracking/schema";

export const getFilterType = (prop?: ContactViewProperty) => {
  if (
    prop?.contactPropertyName &&
    CustomFilterColumns.includes(
      prop.contactPropertyName as BuiltInContactPropertyNames,
    )
  ) {
    return FilterType.customBuiltIn;
  } else if (
    prop?.contactPropertyName &&
    DateFilterColumns.includes(
      prop.contactPropertyName as BuiltInContactPropertyNames,
    )
  ) {
    return FilterType.date;
  } else {
    return FilterType.selection;
  }
};

export const getFilterValue = (column: SelectMenuItem) => {
  const value = column?.contactViewProperty?.filters?.[0]?.values || [];
  const strValue = [...new Set(value?.map((x) => x.toString()))];
  return strValue;
};
