import "@emotion/react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext, useState } from "react";

import { trackEvent } from "utils/tracking";

import Collections from "./Collections";
import MoveMenu from "./Collections/MoveMenu";
import NewCollectionDialog from "./Collections/NewCollectionDialog";
import ListHeader from "./ListHeader";
import ProgressBar from "./ProgressBar";
import FavoritesListMobile from "./mobile";
import styles from "./styles";

interface FavoritesListProps {
  closeDialog: () => void;
}

export default function FavoritesList({ closeDialog }: FavoritesListProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    selectedCollectionId,
    setSelectedCollectionId,
    selectedCollection,
    getCollectionBrands,
    fetchCollections,
    currentTab,
  } = useContext(SavedBrandCollectionsContext);
  const { setBulkBrands, setSelectAll } = useContext(SavedBrandContext);
  const { setAlert } = useContext(AlertContext);

  const [openCollectionDialog, setOpenCollectionDialog] = useState(false);
  const [isBulkMode, setIsBulkMode] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const toggleBulkMode = () => {
    if (selectedCollectionId === null) return;
    trackEvent("Favorites List - Select Button Clicked", {
      "Collection ID": selectedCollectionId,
    });
    if (isBulkMode) {
      setBulkBrands([]);
      setSelectAll(false);
    }
    setIsBulkMode((prev) => !prev);
  };

  const handleBack = () => {
    setSelectedCollectionId(null);
    setIsBulkMode(false);
    setBulkBrands([]);
  };

  const editCollection = () => {
    if (selectedCollectionId === null) return;
    trackEvent("Favorites List - Edit Collection Button Clicked", {
      "Collection ID": selectedCollectionId,
    });
    setOpenCollectionDialog(true);
  };

  const handleCreateNewList = (bulkMove?: boolean) => {
    trackEvent("Favorites List - New List Button Clicked", {
      source: bulkMove ? "Move To Menu" : "New List Button",
    });
    setOpenCollectionDialog(true);
  };

  const displayedBrands =
    selectedCollectionId !== null
      ? getCollectionBrands(selectedCollectionId, currentTab)?.map(
          (x) => x.bentoBrand,
        )
      : [];

  const refreshSavedList = async () => {
    setRefreshLoading(true);
    await fetchCollections();
    setAlert("Successfully refreshed saved brands", "success");
    setRefreshLoading(false);
  };

  const collections = (
    <Collections
      isBulkMode={isBulkMode}
      handleCreateNewList={handleCreateNewList}
      displayedBrands={displayedBrands}
    />
  );

  const header = (
    <ListHeader
      toggleBulkMode={toggleBulkMode}
      isBulkMode={isBulkMode}
      displayedBrands={displayedBrands}
    />
  );

  const footer = (
    <Grid
      container
      justifyContent={isBulkMode ? "flex-end" : "center"}
      sx={styles.footer}
    >
      {isBulkMode ? (
        <MoveMenu
          handleCreateNewList={handleCreateNewList}
          setIsBulkMode={setIsBulkMode}
        />
      ) : (
        <ProgressBar />
      )}
    </Grid>
  );

  return (
    <>
      {isMobileScreen ? (
        <FavoritesListMobile
          collections={collections}
          selectedCollection={selectedCollection}
          handleBack={handleBack}
          header={header}
          footer={footer}
        />
      ) : (
        <Box>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={styles.desktopHeader}
          >
            <Grid container item xs={9} alignItems="center">
              {selectedCollection && (
                <IconButton sx={styles.close} onClick={handleBack}>
                  <Box component="i" className="fa-regular fa-arrow-left" />
                </IconButton>
              )}

              <Typography variant="h6" component="div" sx={{ fontSize: 14 }}>
                {selectedCollection?.name || "SAVED BRANDS"}
                {!selectedCollection?.name && (
                  <IconButton
                    onClick={refreshSavedList}
                    sx={{ color: "black", fontSize: 12 }}
                  >
                    {refreshLoading ? (
                      <CircularProgress size={10} />
                    ) : (
                      <Box component="i" className="fa-regular fa-refresh" />
                    )}
                  </IconButton>
                )}
              </Typography>
              <Box>
                {selectedCollection?.id && selectedCollection?.id > 0 ? (
                  <Tooltip title="Edit Collection">
                    <IconButton sx={styles.close} onClick={editCollection}>
                      <Box component="i" className="fa-regular fa-edit" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Box>
            </Grid>

            {!isMobileScreen && (
              <Grid container item xs justifyContent="flex-end">
                <IconButton sx={styles.close} onClick={closeDialog}>
                  <Box component="i" className="fa-regular fa-xmark" />
                </IconButton>
              </Grid>
            )}
          </Grid>

          {header}
          {collections}
          {footer}
        </Box>
      )}

      <NewCollectionDialog
        open={openCollectionDialog}
        handleClose={() => setOpenCollectionDialog(false)}
        isBulkMode={isBulkMode}
        setIsBulkMode={setIsBulkMode}
      />
    </>
  );
}
