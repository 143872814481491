import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Organization,
  OrganizationProfile,
  OrganizationUser,
  VAStatus,
} from "schemas/dashboard";
import { CustomEvent, Map, SetAlertType } from "schemas/functions";
import { OverridePlanName } from "schemas/payments";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import AdminLoginAs from "../LoginAs";
import styles from "./styles";

interface Props {
  organizationId: number;
  setAlert: SetAlertType;
}

export default function Settings({ organizationId, setAlert }: Props) {
  const { getToken } = useAuth();

  const [loading, setLoading] = useState(false);

  const [organization, setOrganization] = useState<Organization | null>(null);
  const [organizationUser, setOrganizationUser] =
    useState<OrganizationUser | null>(null);
  const [organizationProfile, setOrganizationProfile] =
    useState<OrganizationProfile | null>(null);

  const fetchProfile = async (organizationId: number) => {
    setLoading(true);
    if (!organizationId) return;
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/admin/organization-profiles/${organizationId}`,
      );

      setOrganizationUser(res.organizationUser);
      setOrganization(res.organization);
      setOrganizationProfile(res.organizationProfile);
    } catch (error) {
      setAlert(
        error?.message ||
          "An error occurred, you do not have access to this page",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  const onChangeOrgUser = (name: string, value: any) => {
    setOrganizationUser((prevOrganizationUser: OrganizationUser | null) => {
      if (prevOrganizationUser) {
        return {
          ...prevOrganizationUser,
          [name]: value,
        };
      } else return prevOrganizationUser;
    });
  };

  const onChangeOrg = (name: string, value: any) => {
    setOrganization((prevOrganization: Organization | null) => {
      const copy = makeDeepCopy(prevOrganization);
      if (name === "overridePlanName" && value === "") {
        delete copy[name];
      } else {
        copy[name] = value;
      }
      return copy;
    });
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      if (organizationUser) {
        await fetcherAuth(
          getToken,
          `/api/admin/organization-users/${organizationUser.id}`,
          "PUT",
          {},
          {
            doNotEmail: organizationUser.doNotEmail,
          },
        );
      }

      if (organization) {
        const body: Map = {
          virtualAssistantQuota: organization.virtualAssistantQuota,
        };
        if (organization.vaStatus) {
          body.vaStatus = organization.vaStatus;
        }
        body.overridePlanName = organization?.overridePlanName;
        await fetcherAuth(
          getToken,
          `/api/admin/organizations/${organization.id}`,
          "PUT",
          {},
          body,
        );
      }
      if (organization && !organization?.vaStatus) {
        await fetcherAuth(
          getToken,
          `/api/admin/organizations/${organization.id}/remove-va-status`,
          "PUT",
        );
      }
      setAlert("Profile saved", "success");
    } catch (error) {
      if (error?.message.includes("Google integration")) {
        setAlert(
          "Cannot approve VA user yet. User has not granted Gmail permissions to read / send email",
          "error",
        );
        setOrganization((prevOrganization: Organization | null) => {
          if (prevOrganization) {
            return {
              ...prevOrganization,
              vaStatus: VAStatus.pending,
            };
          } else return prevOrganization;
        });
        return;
      }
      setAlert(
        error.message || "An error occurred when updating profile",
        "warning",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile(organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return (
    <Box sx={{ py: 2 }}>
      <Grid container sx={{ mb: 2 }} alignItems="center" gap={1}>
        Email: {organizationUser?.email}{" "}
        <AdminLoginAs
          organizationId={Number(organizationId)}
          setAlert={setAlert}
        />
        <Grid item xs={12}>
          {organizationProfile?.monthlyIncome &&
          organizationProfile?.monthlyIncome?.length === 2 ? (
            <>
              Monthly Income: ${organizationProfile?.monthlyIncome?.[0]} - $
              {organizationProfile?.monthlyIncome?.[1]}
            </>
          ) : (
            <>Monthly Income: Not Provided</>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ my: 2, width: 400 }} />

      <Typography variant="h6">Update Information</Typography>
      {organizationUser && (
        <Grid container gap={2}>
          <Grid item xs={12}>
            Do Not Email:{" "}
            <Switch
              checked={organizationUser.doNotEmail}
              onChange={(e: CustomEvent) =>
                onChangeOrgUser("doNotEmail", e.target.checked)
              }
              disabled={loading}
              name="doNotEmail"
            />
            {organizationUser.doNotEmail
              ? "User will not receive emails"
              : "User will receive emails"}
          </Grid>

          <Typography variant="h6">Virtual Assistant Settings</Typography>

          <Grid item xs={12} container alignItems="center" gap={1}>
            <Box sx={styles.label}>Virtual Assistant Status:</Box>
            <Select
              value={organization?.vaStatus || ""}
              disabled={loading}
              sx={{ minWidth: 200, ml: 2 }}
              onChange={(e) => onChangeOrg("vaStatus", e.target.value)}
              size="small"
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={VAStatus.pending}>Pending</MenuItem>
              <MenuItem value={VAStatus.approved}>Approved</MenuItem>
              <MenuItem value={VAStatus.disqualified}>Disqualified</MenuItem>
              <MenuItem value={VAStatus.not_interested}>
                Not Interested
              </MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} container alignItems="center" gap={1}>
            <Box sx={styles.label}>Virtual Assistant Quota:</Box>
            <TextField
              type="number"
              size="small"
              sx={{ ml: 2, minWidth: 200 }}
              value={organization?.virtualAssistantQuota}
              onChange={(e) =>
                onChangeOrg("virtualAssistantQuota", e.target.value)
              }
              placeholder="20"
            />
            <FormHelperText>
              You can set the number to 0 if user cancels VA.
            </FormHelperText>
          </Grid>

          <Grid item xs={12} container alignItems="center" gap={1}>
            <Box sx={styles.label}>Override Plan Name:</Box>
            <Select
              value={organization?.overridePlanName || ""}
              disabled={loading}
              sx={{ minWidth: 200, ml: 2 }}
              onChange={(e) => onChangeOrg("overridePlanName", e.target.value)}
              size="small"
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={OverridePlanName.STARTER}>Starter</MenuItem>
              <MenuItem value={OverridePlanName.PRO}>Pro</MenuItem>
              <MenuItem value={OverridePlanName.VA}>Virtual Assistant</MenuItem>
            </Select>
            <FormHelperText>
              You can pick <em>None</em> if you want to stop the plan for the
              user.
            </FormHelperText>
          </Grid>

          <LoadingButton
            variant="contained"
            onClick={handleSave}
            loading={loading}
          >
            Save
          </LoadingButton>
        </Grid>
      )}
    </Box>
  );
}
