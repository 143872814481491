import { useAuth } from "@clerk/clerk-react";
import { createContext, useContext, useState } from "react";
import {
  BentoBrand,
  BentoContact,
  NewBentoContact,
  PreviewMessage,
} from "schemas/dashboard";

import { fetcherAuth } from "utils/api";
import { ON_BEHALF_OF_TOKEN } from "utils/localStorage";
import { trackEvent } from "utils/tracking";
import { useCollection } from "utils/useCollection";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";
import { OutreachTemplatesContext } from "./OutreachTemplates";
import { QuickSendContext } from "./QuickSend";
import { QuickSendDrawerContext } from "./QuickSendDrawer";
import { previewToDraft } from "./helpers/QuickSendHelpers";

export type DraftRef = {
  bentoBrand: BentoBrand;
  emails: PreviewMessage[];
  previewContact: BentoContact | NewBentoContact;
  isAutogenerated?: boolean;
};
interface QuickSendEmailDraftsContextInterface {
  createEmailDraft: (sanitizedData: DraftRef) => void;
  createDraftLoading: number;
  handleOpenCreateTemplateDialog: (hasTemplate: boolean) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  createEmailDraft: defaultContextMissingFunction,
  createDraftLoading: -1,
  handleOpenCreateTemplateDialog: defaultContextMissingFunction,
};

const QuickSendEmailDraftsContext =
  createContext<QuickSendEmailDraftsContextInterface>(defaultInterface);

interface QuickSendEmailDraftsProviderProps {
  children: React.ReactNode;
}

const QuickSendEmailDraftsProvider = ({
  children,
}: QuickSendEmailDraftsProviderProps) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setAlert } = useContext(AlertContext);
  const { setNewlyAddedTemplateIndex } = useContext(QuickSendContext);
  const { addDraftToCollection } = useCollection();

  const { quickSendBrandId } = useContext(QuickSendDrawerContext);
  const { setCreateTemplateDialogOpen } = useContext(OutreachTemplatesContext);
  const [createDraftLoading, setCreateDraftLoading] = useState(-1);
  const [openedCreateTemplateDialog, setOpenedCreateTemplateDialog] =
    useState<boolean>(false);

  const createEmailDraft = async (sanitizedData: DraftRef) => {
    const { emails, previewContact, bentoBrand } = sanitizedData;
    const bentoBrandId = Number(bentoBrand?.id);

    if (!previewContact.email) {
      return;
    }

    setCreateDraftLoading(bentoBrandId);
    addDraftToCollection(sanitizedData.bentoBrand);

    const emailDrafts = emails.map((email) => previewToDraft(email));
    const isAdmin = Boolean(sessionStorage.getItem(ON_BEHALF_OF_TOKEN));
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${bentoBrand?.id}/outreach-draft`,
        "POST",
        {},
        {
          previewContact,
          emailDrafts,
          isAdmin,
        },
      );
      setAlert(`Autosaved your outreach for ${bentoBrand?.brandName}`, "info");
    } catch (error) {
      // Do nothing for now
    } finally {
      setCreateDraftLoading(-1);
    }
  };

  const handleOpenCreateTemplateDialog = (hasTemplate: boolean) => {
    if (!hasTemplate && !openedCreateTemplateDialog && quickSendBrandId > -1) {
      trackEvent("Template Creation Dialog Opened");
      setCreateTemplateDialogOpen(true);
      setOpenedCreateTemplateDialog(true);
      setNewlyAddedTemplateIndex(0);
    }
  };

  return (
    <QuickSendEmailDraftsContext.Provider
      value={{
        createEmailDraft,
        createDraftLoading,
        handleOpenCreateTemplateDialog,
      }}
    >
      {children}
    </QuickSendEmailDraftsContext.Provider>
  );
};

export { QuickSendEmailDraftsProvider, QuickSendEmailDraftsContext };
