import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { VA_MONEY_BACK_DEAL } from "constants/influencer";
import { trackEvent } from "utils/tracking";

type QA = { question: string; answer: string | JSX.Element };

const QuestionsAndAnswers = [
  {
    question: "What is the money-back guarantee?",
    answer: `If you do not earn $${VA_MONEY_BACK_DEAL} or more in booked income within 12 months of starting Bento's Virtual Assistant Income Guaranteed Program, we will refund your $${VA_MONEY_BACK_DEAL} enrollment fee in full.`,
  },
  {
    question: "When does the 12-month Guarantee Period begin?",
    answer:
      "The Guarantee Period starts on the date the first outreach email is sent through Bento's platform—not when you pay the enrollment fee or complete onboarding.",
  },
  {
    question: `What counts as income toward the $${VA_MONEY_BACK_DEAL} guarantee?`,
    answer:
      "Only monetary payments from brand contacts initially contacted via Bento's platform count. Recurring payments from the same brand (e.g., multiple campaigns during the 12 months) are included. Excluded: Gifted exchanges, affiliate program revenue, or barter deals. Deals received outside the platform, such as inbound brand inquiries or repeat deals with brands you previously worked with that were not contacted through Bento, are also excluded.",
  },
  {
    question: `What happens if I earn $${VA_MONEY_BACK_DEAL} from brand deals after the Guarantee Period ends?`,
    answer:
      "Only income booked during the 12-month Guarantee Period counts, even if payments are deposited afterward.",
  },
  {
    question: "What are my responsibilities to qualify for the guarantee?",
    answer: (
      <>
        To remain eligible:
        <ul>
          <li>
            Be responsive to brands: Respond to all brand inquiries within 48
            hours.
          </li>
          <li>
            Grant Gmail access: Allow Bento continuous access to your Gmail
            account for income verification and partnership tracking. Revoking
            access voids the guarantee.
          </li>
          <li>
            Follow onboarding steps: Complete tasks like booking your onboarding
            call and connecting your Gmail to Bento's platform.
          </li>
          <li>
            Act professionally: Negotiate in good faith and avoid rejecting
            reasonable opportunities.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "Can Bento deny my refund request?",
    answer: (
      <>
        Yes, the guarantee is void if:
        <ul>
          <li>
            You fail to meet the commitments above (i.e., don't respond to
            brands, revoke Gmail access).
          </li>
          <li>You provide false or misleading income data.</li>
          <li>
            You deny legitimate partnerships tracked through your Gmail account.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: "What happens if Bento decides to remove me from the Program?",
    answer:
      "If Bento removes you (e.g., due to a mismatch in niche, being too early in your content journey to generate consistent income, or unsupported location), you'll receive a full refund within 30 days. However, no refund will be issued if the removal is due to non-compliance or misconduct.",
  },
  {
    question: "How will refunds be processed?",
    answer:
      "Refunds will be issued to your original payment method in USD within 30 days of Bento approving your claim. Bento is not responsible for any currency conversion fees or exchange rate fluctuations.",
  },
];

export default function AccordionUsage() {
  const trackFaqClick = (qa: QA) => {
    trackEvent("VA Onboarding Flow - FAQ Clicked", {
      question: qa.question,
    });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        FAQ
      </Typography>
      {QuestionsAndAnswers.map((qa, index) => (
        <Accordion
          key={index}
          elevation={0}
          style={{ boxShadow: "none" }}
          sx={{ backgroundColor: "secondary.light" }}
          onClick={() => trackFaqClick(qa)}
        >
          <AccordionSummary
            expandIcon={
              <Box component="i" className="fa-regular fa-chevron-down" />
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {qa.question}
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{qa.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
