import { Box, Chip, Grid, MenuItem, Select } from "@mui/material";
import { CustomEvent } from "schemas/functions";

import { Template } from "features/Influencer/ContactList/schema";

import styles from "./styles";

interface Props {
  templates: Template[];
  handleChange: (e: CustomEvent, index: number) => void;
  filteredOutreachTemplates: Template[];
  templateFromEmailSettings?: number[];
}

const OutreachSelect = ({
  templates,
  handleChange,
  filteredOutreachTemplates,
  templateFromEmailSettings,
}: Props) => {
  return (
    <Grid item xs>
      <Select
        value={templates[0]?.id}
        onChange={(e) => handleChange(e, 0)}
        sx={styles.noBorder}
        defaultValue={templates[0]?.id}
        size="small"
      >
        {filteredOutreachTemplates?.map((template) => (
          <MenuItem key={template.id} value={template.id}>
            <Box sx={styles.templateName}>
              {template.name}
              {template.id &&
                templateFromEmailSettings?.includes(template.id) && (
                  <Chip
                    label="Last Used"
                    size="small"
                    sx={{
                      ml: 1,
                      fontSize: 10,
                      backgroundColor: "primary.light",
                    }}
                  />
                )}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default OutreachSelect;
