import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { AlertContext } from "contexts/Alert";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { useContext, useState } from "react";
import { CustomEvent } from "schemas/functions";

import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { sortTemplates } from "utils/templates";
import { trackEvent } from "utils/tracking";

import SingleTemplate from "./SingleTemplate";
import styles from "./styles";

const TemplateList = () => {
  const { getToken } = useAuth();
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { setDiscoverTab } = useContext(DiscoverViewContext);
  const {
    templateWithIds,
    setCreateTemplateDialogOpen,
    setTemplates,
    defaultTemplates,
  } = useContext(OutreachTemplatesContext);
  const { emailSettings, currentOrg } = useContext(OrganizationUserContext);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );
  const [selectedSettingsTemplate, setSelectedSettingsTemplate] =
    useState<Template | null>(null);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    template: Template,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedSettingsTemplate(template);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedSettingsTemplate(null);
  };

  const emailSettingTemplates = emailSettings?.emailSettingTemplates?.map(
    (template) => template.templateId,
  );

  const handleAddTemplate = () => {
    trackEvent("Add Template Button Clicked", { from: "Sidebar" });
    setCreateTemplateDialogOpen(true);
  };

  const handleSwitchTemplate = (template: Template) => {
    trackEvent("Template Selected", {
      "Template ID": template?.id,
      from: "Sidebar",
    });
    setSelectedTemplate(template);
  };

  const handleDuplicate = async (event: CustomEvent) => {
    event.stopPropagation();
    trackEvent("Template Duplicated", {
      "Template ID": selectedTemplate?.id,
    });

    setLoading(true);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates`,
        "POST",
        {},
        {
          name: `${selectedSettingsTemplate?.name} - Copy`,
          subject: selectedSettingsTemplate?.subject,
          body: selectedSettingsTemplate?.body,
          contentType: selectedSettingsTemplate?.contentType,
          attachment: selectedSettingsTemplate?.attachment,
        },
      );
      setSelectedTemplate(res.template);
      setTemplates((prev) => [...prev, res.template]);
      setAlert("Successfully duplicated template", "success");
      handleClose();
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (event: CustomEvent) => {
    event.stopPropagation();
    setLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates/${selectedSettingsTemplate?.id}`,
        "DELETE",
      );
      setTemplates((prev) =>
        prev.filter((template) => template.id !== selectedSettingsTemplate?.id),
      );
      setAlert("Successfully deleted template", "success");
      handleClose();
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const renderTemplateRow = (template: Template) => {
    return (
      <Grid
        container
        justifyContent="center"
        sx={styles.templateRow}
        alignItems="center"
        onClick={() => handleSwitchTemplate(template)}
        key={template.id}
      >
        <Grid item xs={11}>
          <Typography sx={styles.templateName}>
            {template?.name}{" "}
            <Chip
              size="small"
              sx={{
                fontSize: 12,
                backgroundColor:
                  template?.contentType === TemplateContentType.OUTREACH
                    ? blue[50]
                    : `secondary.light`,
              }}
              label={capitalize(template?.contentType)}
            />
            {template?.id && emailSettingTemplates?.includes(template.id) && (
              <Typography sx={{ fontSize: 12, color: "success.main" }}>
                <strong>Last Used</strong>
              </Typography>
            )}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              color: "text.secondary",
            }}
            dangerouslySetInnerHTML={{ __html: template?.subject }}
          ></Typography>
        </Grid>
        <Grid item xs>
          <Tooltip title="Settings">
            <IconButton
              sx={{ color: "primary.main", fontSize: 12 }}
              onClick={(e) => handleClick(e, template)}
            >
              <Box component="i" className="fa-regular fa-ellipsis-vertical" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const closeDialog = () => {
    setDiscoverTab(null);
  };

  const goBack = (e: CustomEvent) => {
    setSelectedTemplate(null);
    e.stopPropagation();
  };

  const renderView = () => {
    if (selectedTemplate && selectedTemplate?.body) {
      return (
        <SingleTemplate
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      );
    } else {
      return (
        <>
          {sortTemplates(templateWithIds, defaultTemplates)?.map(
            (template, index) => renderTemplateRow(template),
          )}

          <Grid item xs={12} sx={styles.addTemplate}>
            <Link sx={styles.templateLink} onClick={handleAddTemplate}>
              Create New Template +
            </Link>
          </Grid>
        </>
      );
    }
  };

  return (
    <Box sx={styles.root}>
      <Grid
        container
        justifyContent="space-between"
        onClick={closeDialog}
        alignItems="center"
        sx={styles.header}
      >
        {selectedTemplate && (
          <IconButton sx={styles.close} onClick={goBack}>
            <Box component="i" className="fa-regular fa-arrow-left" />
          </IconButton>
        )}
        <Typography noWrap sx={{ maxWidth: 300, fontSize: 14 }} variant="h6">
          {selectedTemplate ? selectedTemplate?.name : "TEMPLATES"}
        </Typography>
        <IconButton sx={styles.close}>
          <Box component="i" className="fa-regular fa-xmark" />
        </IconButton>
      </Grid>
      {renderView()}

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleDuplicate} disabled={loading}>
          <ListItemIcon>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Box component="i" className="fa-solid fa-clipboard" />
            )}
          </ListItemIcon>
          <Typography variant="inherit">Copy Template</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete} disabled={loading}>
          <ListItemIcon>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Box component="i" className="fa-solid fa-trash" />
            )}
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TemplateList;
