import { SubscriptionContext } from "contexts/Subscription";
import { UserRequestsContext } from "contexts/UserRequests";
import { useContext } from "react";
import {
  AdditionalContactNotification,
  BentoBrand,
  CombinedRequest,
  RequestStatus,
  RequestType,
  SavedBrandStatus,
} from "schemas/dashboard";

import { makeDeepCopy } from "./updateLocalState";

export const useRequests = () => {
  const { decreaseBrandRequestsCount } = useContext(SubscriptionContext);
  const { setUserRequests, userRequests, setTotal } =
    useContext(UserRequestsContext);
  const getType = (combinedRequest: CombinedRequest) => {
    if (combinedRequest?.additionalContactNotificationUpdatedAt) {
      return RequestType.contact_notifications;
    } else if (combinedRequest?.brandNotificationUpdatedAt) {
      return RequestType.brand_notifications;
    } else {
      return RequestType.user_requests;
    }
  };

  const moveFromUnsentToSent = (bentoBrand: BentoBrand) => {
    const unsentRequest = userRequests(SavedBrandStatus.unsent)?.find(
      (x) => Number(x?.bentoBrand?.id) === Number(bentoBrand.id),
    );
    if (unsentRequest) {
      modifyTotal(SavedBrandStatus.unsent, "remove");
      setUserRequests(SavedBrandStatus.unsent)((prev) =>
        prev.filter(
          (x) => Number(x?.bentoBrand?.id) !== Number(bentoBrand?.id),
        ),
      );
    }
    const sentIndex = userRequests(SavedBrandStatus.sent)?.findIndex(
      (x) => Number(x?.bentoBrand?.id) === Number(bentoBrand.id),
    );
    if (sentIndex === -1 && unsentRequest) {
      setUserRequests(SavedBrandStatus.sent)((prev) => {
        const copy = makeDeepCopy(prev);
        copy.push({
          ...unsentRequest,
          earliestPendingSendAt: bentoBrand?.earliestPendingSendAt,
          latestEmailCompletedAt: bentoBrand?.latestEmailCompletedAt,
        });
        return copy;
      });
      modifyTotal(SavedBrandStatus.sent, "add");
    }
  };

  const removeFromRequests = (
    brand: BentoBrand,
    status: SavedBrandStatus,
    key: keyof CombinedRequest,
  ) => {
    const index = _findInUserRequest(status, brand, key);
    if (index > -1) {
      setUserRequests(status)((prev) => {
        const copy = makeDeepCopy(prev);
        copy.splice(index, 1);
        return copy;
      });
      modifyTotal(status, "remove");
    }
  };

  const updateContactNotifications = (
    contactNotification: AdditionalContactNotification,
  ) => {
    const bentoBrand = contactNotification?.bentoBrand;
    if (!bentoBrand) return;
    const {
      earliestPendingSendAt,
      latestEmailCompletedAt,
      orgHasOutreachContact,
    } = bentoBrand;
    const status = orgHasOutreachContact
      ? SavedBrandStatus.sent
      : SavedBrandStatus.unsent;
    const index = _findInUserRequest(
      status,
      bentoBrand,
      "additionalContactNotificationStatus",
    );
    if (index === -1) {
      const combinedRequest: CombinedRequest = {
        additionalContactNotificationStatus: RequestStatus.pending,
        additionalContactNotificationUpdatedAt: contactNotification.updatedAt,
        earliestPendingSendAt,
        latestEmailCompletedAt,
        bentoBrand,
      };
      setUserRequests(status)((prev) => [combinedRequest, ...prev]);
      modifyTotal(status || SavedBrandStatus.unsent, "add");
      decreaseBrandRequestsCount();
    }
  };

  const modifyTotal = (status: SavedBrandStatus, action: "add" | "remove") => {
    setTotal((prev) => ({
      ...prev,
      [status]:
        action === "add"
          ? (prev[status] || 0) + 1
          : Math.max((prev[status] || 0) - 1, 0),
    }));
  };

  const _findInUserRequest = (
    status: SavedBrandStatus,
    brand: BentoBrand,
    key: keyof CombinedRequest,
  ) => {
    return userRequests(status)?.findIndex(
      (x) => Number(x?.bentoBrand?.id) === Number(brand.id) && x[key],
    );
  };

  return {
    getType,
    moveFromUnsentToSent,
    removeFromRequests,
    modifyTotal,
    updateContactNotifications,
  };
};
