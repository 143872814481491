import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { BentoBrand } from "schemas/dashboard";

import { Template } from "features/Influencer/ContactList/schema";
import TemplateBody from "features/Influencer/Templates/SharedComponents/TemplateBody";
import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import { Preview } from "../schema";

interface Props {
  previewTemplate: (templates: Template[], bentoBrand: BentoBrand) => void;
  preview: Preview;
  setPreview: Dispatch<SetStateAction<Preview | undefined>>;
}

const PreviewEmail = ({ preview, previewTemplate, setPreview }: Props) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const [loading, setLoading] = useState(false);
  const { templates, setTemplates } = useContext(OutreachTemplatesContext);
  const { setAlert, setErrorAlert } = useContext(AlertContext);

  const [template, setTemplate] = useState<Template | undefined>(undefined);

  const handleEditTemplate = (templateId?: number) => {
    if (!templateId) return;
    const editTemplate = templates?.find((t) => t.id === templateId);
    if (editTemplate) {
      setTemplate(editTemplate);
    } else {
      setAlert(
        "Cannot find the templates to edit. Please reload and retry again",
        "error",
      );
    }
  };

  const isEditing = !!template?.id;

  const handleSaveTemplate = async () => {
    setLoading(true);

    // Refetch previews
    const copy = makeDeepCopy(preview);
    copy.templates = copy.templates.map((t: Template) =>
      t.id === template?.id ? template : t,
    );
    previewTemplate(copy.templates, copy.bentoBrand);
    setPreview(copy);
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/templates/${template?.id}`,
        "PUT",
        {},
        {
          ...template,
        },
      );
      setAlert("Template Saved", "success");
      setTemplates((prev) =>
        prev.map((t) => (t.id === template?.id ? res.template : t)),
      );
      setTemplate(undefined);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const outreachMessages = preview?.outreachMessages;

  return (
    <>
      <Divider sx={{ my: 2, mx: -3 }} />

      {outreachMessages?.map((message, index) => (
        <Box>
          {!isEditing && (
            <Grid container alignItems="center" gap={1}>
              <Chip
                label={message.templateName}
                sx={{ backgroundColor: "secondary.light" }}
              />

              {message?.templateId && (
                <Tooltip title="Edit Templates">
                  <IconButton
                    sx={{ fontSize: 14, color: "black" }}
                    onClick={() => handleEditTemplate(message?.templateId)}
                  >
                    <Box component="i" className="fa-regular fa-edit" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          )}

          {template?.id && template?.id === message?.templateId && (
            <>
              <TemplateBody
                editTemplate={template}
                // @ts-ignore: Allow setTemplate to make a template undefined (meaning the user did not want to discard their changes) in preview.
                setEditTemplate={setTemplate}
              />
              <Grid container alignItems="center" gap={1} sx={{ mb: 3 }}>
                <LoadingButton
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  loading={loading}
                  onClick={handleSaveTemplate}
                >
                  Save
                </LoadingButton>
                <Button
                  size="small"
                  color="secondary"
                  sx={{ textTransform: "none" }}
                  onClick={() => setTemplate(undefined)}
                >
                  Discard
                </Button>
              </Grid>
            </>
          )}

          {index === 0 && (
            <Typography variant="h6">
              <strong>
                {message?.subject && (
                  <Box
                    dangerouslySetInnerHTML={{ __html: message.subject }}
                  ></Box>
                )}
              </strong>
            </Typography>
          )}

          <Box sx={{ my: 1 }}>
            {message?.missingValues && message?.missingValues?.length > 0 && (
              <Alert severity="error" sx={{ my: 1 }}>
                This templates contains variables that need to be filled in:{" "}
                {message?.missingValues.join(", ")}
              </Alert>
            )}
            {message.body && (
              <Box
                dangerouslySetInnerHTML={{
                  __html: message.body.replaceAll(
                    `<p style="margin: 0;">`,
                    `<p>`,
                  ),
                }}
              ></Box>
            )}
          </Box>
          <Divider sx={{ my: 2, mx: -3 }} />
        </Box>
      ))}
    </>
  );
};

export default PreviewEmail;
