import { Box, Checkbox, TableCell } from "@mui/material";

import { getStyles } from "./helpers";
import styles from "./styles";

interface Props {
  isSticky?: boolean;
  handleSelectAll: (checked: boolean) => void;
  selectAllIsChecked: boolean;
}

const SelectHeader = ({
  isSticky,
  handleSelectAll,
  selectAllIsChecked,
}: Props) => {
  return (
    <TableCell
      // @ts-ignore
      style={styles.tableCell(isSticky)}
      sx={getStyles()}
    >
      <Checkbox
        checked={selectAllIsChecked}
        onChange={(e) => handleSelectAll(e.target.checked)}
        icon={
          <Box
            component="i"
            className="fa-regular fa-square"
            sx={{ color: "primary.main" }}
          />
        }
        checkedIcon={
          <Box
            component="i"
            className="fa-solid fa-minus-square"
            sx={{ color: "primary.main" }}
          />
        }
      />
    </TableCell>
  );
};

export default SelectHeader;
