import { Box, FormControlLabel, Switch } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface Props {
  setLastUsedTemplateSelected: Dispatch<SetStateAction<boolean>>;
  lastUsedTemplateSelected: boolean;
  fromTrackingTable?: boolean;
}

const UsePreviousTemplateToggle = ({
  fromTrackingTable,
  setLastUsedTemplateSelected,
  lastUsedTemplateSelected,
}: Props) => {
  return (
    <Box component="span" sx={{ ml: 1 }}>
      {fromTrackingTable && (
        <FormControlLabel
          control={
            <Switch
              size="small"
              onChange={() => setLastUsedTemplateSelected((prev) => !prev)}
              checked={lastUsedTemplateSelected}
            />
          }
          label="Use previous templates"
        />
      )}
    </Box>
  );
};

export default UsePreviousTemplateToggle;
