import { Box, Button, FormHelperText, Grid } from "@mui/material";
import { SendOption } from "schemas/dashboard";

import styles from "../MainDialogBody/styles";

interface Props {
  sendOption?: SendOption;
  pickOption: (option: SendOption) => void;
}
const SendTemplateOption = ({ sendOption, pickOption }: Props) => {
  return (
    <Box>
      <strong>How do you want to send your templates?</strong>
      <FormHelperText>
        Same: same templates for all brands. <br />
        By Category: different templates for each category.
      </FormHelperText>

      <Grid container gap={2} sx={{ py: 2 }}>
        <Button
          sx={[
            styles.sendOption,
            sendOption === SendOption.same ? styles.selected : {},
          ]}
          onClick={() => pickOption(SendOption.same)}
        >
          Same
        </Button>
        <Button
          sx={[
            styles.sendOption,
            sendOption === SendOption.by_category ? styles.selected : {},
          ]}
          onClick={() => pickOption(SendOption.by_category)}
        >
          By Category
        </Button>
      </Grid>
    </Box>
  );
};

export default SendTemplateOption;
