import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Typography } from "@mui/material";
import { SlideAnimationContext } from "contexts/SlideAnimation";
import { Dispatch, SetStateAction, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";

import { TemplateHighlight } from "features/Influencer/Templates/TemplatesTable/CreateTemplate/PresetFlow/schema";
import { getHighlights } from "utils/templates";
import { makeDeepCopy } from "utils/updateLocalState";

import { onboardingNavigate } from "../helper";
import styles from "../styles";

interface PitchingHighlightsProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
}

const PitchingHighlights = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
}: PitchingHighlightsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const slideContext = useContext(SlideAnimationContext);

  const handleBack = async () => {
    if (slideContext) {
      await slideContext.slideOutDown();
      slideContext.slideInDown();
    }
    navigate(
      `/${onboardingNavigate(location.pathname, onboardingForm, "back")}${
        location.search
      }`,
    );
  };

  const selectHighlight = (highlight: TemplateHighlight) => {
    const onboardingFormCopy = makeDeepCopy(onboardingForm);
    if (!onboardingFormCopy.defaultTemplateHighlights) {
      onboardingFormCopy.defaultTemplateHighlights = [highlight];
    } else if (
      onboardingFormCopy.defaultTemplateHighlights.includes(highlight)
    ) {
      onboardingFormCopy.defaultTemplateHighlights =
        onboardingFormCopy.defaultTemplateHighlights.filter(
          (t: TemplateHighlight) => t !== highlight,
        );
    } else {
      onboardingFormCopy.defaultTemplateHighlights.push(highlight);
    }
    setOnboardingForm(onboardingFormCopy);
  };

  const handleNext = (e: CustomEvent) => {
    e.preventDefault();
    saveOnboardingForm(onboardingForm, location.pathname);
  };

  return (
    <form onSubmit={handleNext}>
      <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
        <Box
          component="i"
          color="secondary.dark"
          sx={{ pr: 2 }}
          className="fa-solid fa-highlighter"
        />
        What do you want to highlight in an email?
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 3, textAlign: "center" }}>
        Select all that apply
      </Typography>

      <Grid container justifyContent="center" gap={1}>
        {getHighlights(
          onboardingForm?.isUgcCreator,
          onboardingForm?.isInfluencer,
        ).map((highlight) => (
          <Grid item xs="auto" key={highlight.key}>
            <LoadingButton
              sx={[
                styles.pitchButton,
                onboardingForm.defaultTemplateHighlights?.includes(
                  highlight.key,
                ) && styles.selectedPitchButton,
              ]}
              variant="outlined"
              onClick={() => selectHighlight(highlight.key)}
              disableElevation
            >
              <Box
                sx={styles.pitchIcon}
                component="i"
                className={highlight.icon}
              />
              {highlight.value}
            </LoadingButton>
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent="flex-start" sx={{ mt: 2 }}>
        <Grid item xs="auto">
          <Button onClick={handleBack} color="secondary" disableElevation>
            Back
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          <Button
            disableElevation
            type="submit"
            variant="contained"
            disabled={
              !onboardingForm.defaultTemplateHighlights ||
              onboardingForm.defaultTemplateHighlights.length === 0
            }
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PitchingHighlights;
