import { grey } from "@mui/material/colors";

const styles = {
  button: {
    p: 2,
    width: "100%",
    backgroundColor: `${grey[200]}`,
    border: `1px solid ${grey[300]}`,
    borderRadius: 2,
    my: 1,
    cursor: "pointer",
    "&:hover": {
      color: "black",
      backgroundColor: "primary.light",
    },
  },
  active: {
    backgroundColor: "primary.light",
    border: `1px solid ${grey[500]}`,
    color: "black",
  },
};

export default styles;
