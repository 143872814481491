import {
  Box,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Editor as TipTapEditor, useEditor } from "@tiptap/react";
import { AlertContext } from "contexts/Alert";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { unstable_usePrompt } from "react-router-dom";
import { CustomEvent } from "schemas/functions";

import Editor from "components/Editor";
import { useMergeTags } from "components/Editor/MergeTag/useMergeTags";
import { extensions } from "components/Editor/utils";
import { MERGE_TAGS } from "constants/templates";
import AttachmentManager from "features/Influencer/ContactList/AttachmentManager";
import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import useTemplate from "utils/useTemplate";

const styles = {
  errorContent: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
      "&:hover fieldset": {
        borderColor: "red",
      },
      "& fieldset": {
        borderColor: "red",
      },
    },
  },
  errorText: { color: "red" },
};

interface TemplateBodyProps {
  editTemplate: Template;
  setEditTemplate: Dispatch<SetStateAction<Template>>;
  setIsDirty?: Dispatch<SetStateAction<boolean>>;
  isNew?: boolean;
  allowChangeType?: boolean;
  displayError?: boolean;
  isDirty?: boolean;
  minimalist?: boolean;
}

export default function TemplateBody({
  editTemplate,
  setEditTemplate,
  setIsDirty,
  isNew = false,
  allowChangeType = false,
  displayError,
  isDirty,
  minimalist = false,
}: TemplateBodyProps) {
  const { setAlert } = useContext(AlertContext);
  const { templates, outreachTemplates } = useContext(OutreachTemplatesContext);
  const [attachmentDialogOpen, setAttachmentDialogOpen] =
    useState<boolean>(false);
  const { getUnfilledVariables } = useTemplate();

  const [toggleMergeTagTooltip, toggleVariableTooltip] = useMergeTags();

  const [deletedMergeTag, setDeletedMergeTag] = useState("");
  const [unfilledVariables, setUnfilledVariables] = useState<string[]>([]);

  const displayMergeTagWarning = (deletedMergeTag: string) => {
    setDeletedMergeTag(deletedMergeTag);
  };

  const subjectEditor = useEditor(
    {
      extensions: extensions({
        isPlain: true,
        suggestionOptions: MERGE_TAGS,
        placeholderText: "Collaborating with a Beauty Influencer",
      }),
      content: editTemplate?.subject,
      onUpdate({ editor }) {
        setEditTemplate((prevTemplate: Template) => {
          return { ...prevTemplate, subject: editor.getHTML() };
        });

        if (setIsDirty) setIsDirty(true);
      },
    },
    [],
  ) as TipTapEditor;

  const editor = useEditor(
    {
      extensions: extensions({
        toggleVariableTooltip,
        toggleMergeTagTooltip,
        suggestionOptions: MERGE_TAGS,
        displayMergeTagWarning,
        placeholderText:
          "Hi there, I'm a fan of your brand's work in the industry, and I'm reaching out to see if you're interested in a collaboration...",
      }),
      content: editTemplate?.body,
      onUpdate({ editor }) {
        setEditTemplate((prevTemplate: Template) => {
          return { ...prevTemplate, body: editor.getHTML() };
        });
        if (setIsDirty) setIsDirty(true);
      },
    },
    // There is an issue here for all theses editors between new and loading template
    [isNew === true || !!editTemplate.body || editTemplate?.contentType],
  ) as TipTapEditor;

  const handleTemplateChange = (e: CustomEvent) => {
    const { name, value } = e.target;

    if (
      name === "contentType" &&
      value === TemplateContentType.FOLLOWUP &&
      outreachTemplates?.length === 1
    ) {
      const outreach = outreachTemplates[0];
      if (outreach?.id === editTemplate?.id) {
        setAlert("You need at least one outreach template", "warning");
        return;
      }
    }

    setEditTemplate((prevTemplate: Template) => {
      return { ...prevTemplate, [name]: value };
    });

    if (setIsDirty) setIsDirty(true);
  };

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(editTemplate?.body || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTemplate?.contentType]);

  useEffect(() => {
    const errors = getUnfilledVariables(editTemplate?.body || "");
    setUnfilledVariables(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTemplate?.body]);

  const hasFirstNameTag = editor && editor?.getHTML()?.includes("First Name");

  const hasBrandNameTag = editor && editor?.getHTML()?.includes("Brand Name");

  const hasRedBorder = !editTemplate?.name && displayError;

  unstable_usePrompt({
    message:
      "You have an unsaved template. Are you sure you want to navigate away from this page?",
    when: ({ currentLocation, nextLocation }) =>
      Boolean(isDirty) && currentLocation.pathname !== nextLocation.pathname,
  });

  const renderVariable = (label: string) => {
    return (
      <Box
        component="span"
        sx={{
          p: 0.5,
          mx: 0.5,
          backgroundColor: "#fce6df",
          color: "black",
          borderRadius: 2,
        }}
        key={label}
      >
        {label}
      </Box>
    );
  };

  return (
    <>
      <Grid container gap={1}>
        <Grid item xs={12}>
          {hasRedBorder && (
            <Typography variant="body2" sx={styles.errorText}>
              Please fill in the template name
            </Typography>
          )}
          <TextField
            required
            value={editTemplate?.name}
            onChange={handleTemplateChange}
            fullWidth
            label="Template Name"
            placeholder="Template Name"
            name="name"
            sx={[{ mt: 1 }, hasRedBorder ? styles.errorContent : {}]}
          />
        </Grid>
        {allowChangeType && templates?.length > 0 && (
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 12 }}>Template Type</Typography>
            <Select
              onChange={handleTemplateChange}
              label=""
              name="contentType"
              value={editTemplate?.contentType}
              fullWidth
            >
              <MenuItem value={TemplateContentType.OUTREACH}>Outreach</MenuItem>
              <MenuItem value={TemplateContentType.FOLLOWUP}>Followup</MenuItem>
            </Select>
          </Grid>
        )}

        {editTemplate?.contentType !== TemplateContentType.FOLLOWUP && (
          <Grid item xs={12} sx={{ fontSize: 12 }}>
            Subject *{" "}
            {!minimalist && (
              <Typography
                variant="caption"
                sx={{ color: "secondary.main" }}
              >{`Utilize personalization tags {Brand Name} and {First Name}`}</Typography>
            )}
            <Editor editor={subjectEditor} hideMenu mergeTags={MERGE_TAGS} />
          </Grid>
        )}

        <Editor
          editor={editor}
          mergeTags={!minimalist ? MERGE_TAGS : []}
          allowImage={true}
          allowAttachment={true}
          openAttachmentManager={() => {
            setAttachmentDialogOpen(true);
          }}
          attachment={editTemplate?.attachment}
          removeAttachment={() => {
            setEditTemplate((prevTemplate: Template) => {
              return { ...prevTemplate, attachment: undefined };
            });
            if (setIsDirty) setIsDirty(true);
          }}
        />

        <FormHelperText>
          {unfilledVariables?.length > 0 && (
            <>
              Please fill out {unfilledVariables?.map((x) => renderVariable(x))}
            </>
          )}
          {deletedMergeTag === "Brand Name" && !hasBrandNameTag && (
            <>
              Keep{" "}
              <Box
                component="span"
                sx={{
                  backgroundColor: grey[100],
                  p: 0.5,
                  borderRadius: 2,
                  mx: 0.5,
                }}
              >
                Brand Name
              </Box>{" "}
              tag so Bento can customize each outreach emails to the Brand's
              name for you.
            </>
          )}
          {deletedMergeTag === "First Name" && !hasFirstNameTag && (
            <>
              Keep{" "}
              <Box
                component="span"
                sx={{
                  backgroundColor: grey[100],
                  p: 0.5,
                  borderRadius: 2,
                  mx: 0.5,
                }}
              >
                First Name{" "}
              </Box>{" "}
              tag so Bento can customize each outreach emails to the recipient's
              first name for you.
            </>
          )}
        </FormHelperText>
      </Grid>
      <AttachmentManager
        enteredSelection={(attachment: string) => {
          setEditTemplate((prevTemplate: Template) => {
            return { ...prevTemplate, attachment };
          });
          if (setIsDirty) setIsDirty(true);
        }}
        open={attachmentDialogOpen}
        setOpen={setAttachmentDialogOpen}
      />
    </>
  );
}
