import { Box, FormHelperText, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SelectMenuItem } from "contexts/TrackingColumns";
import moment from "moment";
import { BuiltInContactPropertyName } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { makeDeepCopy } from "utils/updateLocalState";

import { getFilterValue } from "./helpers";

interface Props {
  column: SelectMenuItem;
  index: number;
  onChangeOption: (
    column: SelectMenuItem,
    index: number,
    value: string[],
  ) => void;
}

export default function BasicDateRangePicker({
  column,
  index,
  onChangeOption,
}: Props) {
  const currentValue = getFilterValue(column);
  const handleChange = (e: CustomEvent) => {
    const { name, value } = e.target;
    let copy = makeDeepCopy(currentValue);
    if (copy?.length === 0) {
      copy = ["", ""];
    }
    if (name === "start") {
      copy[0] = value;
    } else {
      copy[1] = value;
    }
    onChangeOption(column, index, copy);
  };

  const today = moment().format("YYYY-MM-DD");
  let max = "";
  if (
    column?.contactViewProperty?.contactPropertyName ===
    BuiltInContactPropertyName.brandLastGmailMessageDate
  ) {
    max = today;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ flexGrow: 1 }} alignItems="center">
        <FormHelperText sx={{ ml: 0 }}>Select date range</FormHelperText>

        <TextField
          type="date"
          name="start"
          size="small"
          sx={{ mr: 1 }}
          onChange={handleChange}
          value={currentValue?.[0]}
          inputProps={{ max }}
        />

        <TextField
          type="date"
          name="end"
          size="small"
          onChange={handleChange}
          value={currentValue?.[1]}
          inputProps={{ max }}
        />
      </Box>
    </LocalizationProvider>
  );
}
