import { Box, Button, Grid, Typography } from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import {
  BentoBrand,
  SavedBrandCollection,
  SavedBrandStatus,
} from "schemas/dashboard";

import { trackEvent } from "utils/tracking";

import BrandList from "./BrandList";
import Collection from "./SingleCollection";
import styles from "./styles";

interface Props {
  isBulkMode: boolean;
  handleCreateNewList: (bulkMove?: boolean) => void;
  displayedBrands: BentoBrand[] | undefined;
}

const Collections = ({
  isBulkMode,
  handleCreateNewList,
  displayedBrands,
}: Props) => {
  const {
    collections,
    fetchLoading,
    fetchSavedBrandsForCollection,
    currentTab,
    selectedCollectionId,
    setSelectedCollectionId,
    selectedCollection,
  } = useContext(SavedBrandCollectionsContext);
  const { moveLoading } = useContext(SavedBrandContext);

  const headRef = useRef<HTMLDivElement>(null);
  const [paginationRef, isPaginationRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const total =
    (currentTab === SavedBrandStatus.sent
      ? selectedCollection?.sentCount
      : selectedCollection?.unsentCount) || 0;

  const loading = fetchLoading === selectedCollectionId || moveLoading;

  useEffect(() => {
    if (
      (displayedBrands === undefined || displayedBrands.length === 0) &&
      selectedCollection &&
      !loading
    ) {
      fetchSavedBrandsForCollection(selectedCollection?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection?.id, currentTab]);

  useEffect(() => {
    if (
      isPaginationRefVisible &&
      selectedCollection &&
      displayedBrands &&
      displayedBrands.length < total &&
      !loading
    ) {
      fetchSavedBrandsForCollection(selectedCollection?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPaginationRefVisible,
    selectedCollection?.id,
    currentTab,
    displayedBrands?.length,
  ]);

  useEffect(() => {
    if (headRef && headRef.current) {
      headRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection?.id]);

  const handleClickCollection = (collection: SavedBrandCollection) => {
    setSelectedCollectionId(collection.id);
    trackEvent("Favorites List - Collection Viewed", {
      "Collection Name": collection.name,
      "Collection ID": collection.id,
    });
  };

  const defaultCollection = Object.values(collections)?.find(
    (collection) => collection.isDefaultForVa,
  );

  const otherCollections = Object.values(collections)?.filter(
    (collection) => !collection.isDefaultForVa,
  );

  if (selectedCollection) {
    return (
      <Grid>
        <div ref={headRef} />
        {(displayedBrands === undefined || displayedBrands.length > 0) &&
        total !== 0 ? (
          <BrandList
            displayedBrands={displayedBrands || []}
            loading={loading}
            collectionId={selectedCollection.id}
            isBulkMode={isBulkMode}
            ref={paginationRef}
            isDefaultCollection={selectedCollection.isDefaultForVa}
          />
        ) : (
          <Typography sx={styles.caption}>
            <em>You do not have any {currentTab} brands on this list</em>
          </Typography>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid sx={styles.container}>
        {defaultCollection && (
          <Collection
            key={defaultCollection.id}
            collection={defaultCollection}
            setSelectedCollection={() =>
              handleClickCollection(defaultCollection)
            }
          />
        )}
        {otherCollections?.map((collection) => (
          <Collection
            key={collection.id}
            collection={collections[collection.id]}
            setSelectedCollection={() =>
              handleClickCollection(collections[collection.id])
            }
          />
        ))}
        <Box sx={styles.newList} onClick={() => handleCreateNewList(false)}>
          <Button sx={{ textTransform: "none" }}>New List +</Button>
        </Box>
      </Grid>
    );
  }
};

export default Collections;
